<template>
  <div v-if="color && displayValues" :class="wrapperClass">
    <v-chip
      v-for="v in displayValues"
      :key="v"
      :color="color"
      :class="labelClass"
      small
      :dark="dark"
      class="mx-1 rounded-0"
    >
      {{ v }}
    </v-chip>
  </div>
  <span v-else>{{ value }}</span>
</template>

<script>
export default {
  name: 'JMATypeLabel',
  props: {
    value: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    labelClass: {
      type: String,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: 'mx-n1',
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayValues() {
      const valueArray = this.value?.split(';').filter((v) => !!v);
      return valueArray?.length > 0 ? valueArray : null;
    },
  },
};
</script>
