<template>
  <v-menu
    v-model="megamenu"
    tile
    :close-on-content-click="false"
    offset-y
    nudge-bottom="5"
    min-width="100%"
    content-class="elevation-0"
    allow-overflow
  >
    <template #activator="{ on: onMenu }">
      <v-btn
        text
        tile
        :small="$vuetify.breakpoint.xs"
        class="pl-sm-3 pr-sm-2 pl-2 pr-1"
        v-on="{ ...onMenu }"
      >
        リンク集
        <v-icon v-if="megamenu" right>
          mdi-chevron-up
        </v-icon>
        <v-icon v-else right>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>

    <v-card tile outlined elevation="0">
      <div class="d-flex justify-center">
        <!-- アイコン羅列部 -->
        <v-col
          :class="{ 'pa-0': $vuetify.breakpoint.xs }"
          :style="{ 'max-width': sectionMenu.maxWidth }"
        >
          <v-row no-gutters :class="[$vuetify.breakpoint.xs ? 'pa-0' : 'pa-2']">
            <v-col
              v-for="chunkItem in sectionMenu.chunkItems"
              :key="chunkItem.id"
              :cols="12 / sectionMenu.chunkCols"
            >
              <div v-for="item in chunkItem" :key="item.id">
                <v-card-title
                  v-if="!!item.category"
                  :class="['pa-2', 'pl-md-1', 'pb-0']"
                >
                  {{ item.category }}
                </v-card-title>
                <div v-else>
                  <a
                    class="v-card v-card--link v-sheet theme--light elevation-0 rounded-0"
                    :href="
                      $transition.getToLink(item.path, null, {
                        notApplyRetUrl: true,
                      })
                    "
                    target="_blank"
                    @click="megamenu = false"
                  >
                    <v-card-title
                      :class="['pa-1', 'subtitle-1', 'pl-2', 'pl-md-0']"
                    >
                      <v-icon :color="item.color">
                        {{ item.icon }}
                      </v-icon>
                      <span class="pl-1"> {{ item.title }}</span>
                      <v-spacer></v-spacer>
                      <v-icon class="mr-2 d-flex d-sm-none">
                        mdi-chevron-right
                      </v-icon>
                    </v-card-title>
                  </a>
                  <div
                    v-if="helpSwitch"
                    class="caption text--secondary px-2 pb-2"
                    style="white-space: pre-line;"
                  >
                    {{ item.hint }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-switch
              v-model="helpSwitch"
              label="説明表示"
              class="pl-3"
              dense
            ></v-switch>
          </div>
        </v-col>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    sectionId: 'link',
  }),

  computed: {
    megamenu: {
      get() {
        if (!this.sectionId) return false;
        return this.$store.state.menu.activeMenues.includes(this.sectionId);
      },
      set(newValue) {
        if (!this.sectionId) return;
        this.$store.dispatch('menu/setActiveMenues', {
          name: this.sectionId,
          value: newValue,
        });
      },
    },
    sectionMenu() {
      // リンク集をstoreから読み込み
      const linkCollection = this.$store.state.menu.linkCollection;
      if (!linkCollection) return {};
      let categories = _.unionBy(linkCollection, 'Category__c');
      let items = [];
      categories.forEach((element) => {
        // カテゴリを先頭の要素として設定しカテゴリタイトルとする
        let value = _.concat(
          { category: element.Category__c },
          linkCollection
            .filter((item) => item.Category__c === element.Category__c)
            .map((item) => {
              return {
                title: item.Name,
                icon: 'mdi-square-small',
                path: item.URL__c,
                hint: item.Description__c,
                sort: item.Sort__c,
                color: 'light-grey',
              };
            }),
        );
        items.push(value);
      });

      let chunkSize = {
        xs: 1, // xs,smは固定列表示
        sm: 2,
        md: items.length > 2 ? 2 : items.length,
        lg: items.length > 3 ? 3 : items.length,
        xl: items.length > 4 ? 4 : items.length,
      };
      let chunkItems = items;
      let chunkCols = chunkSize[this.$vuetify.breakpoint.name];
      // 列数が異なる場合のデザイン上の間延びを避ける
      let maxWidth =
        { 2: '800px', 3: '1200px', 4: '1300px' }[chunkCols] || '1500px';
      return { chunkItems, chunkCols, maxWidth };
    },
    helpSwitch: {
      get() {
        return this.$store.state.menu.showHelp;
      },
      set(newValue) {
        this.$store.dispatch('menu/setShowHelp', !!newValue);
      },
    },
  },
};
</script>
