<template>
  <v-overlay
    v-if="overlay"
    :value="loadingDisplay"
    :z-index="999"
    :opacity="0.5"
    color="white"
  >
    <slot />
  </v-overlay>
  <v-progress-linear
    v-else-if="progress"
    :active="loadingDisplay"
    indeterminate
    absolute
    v-bind="linearProps"
    color="light-blue"
  ></v-progress-linear>
</template>

<script>
export default {
  props: {
    overlay: { type: Boolean, default: false },
    progress: { type: Boolean, default: false },
    linearProps: {
      type: Object,
      default: () => ({
        bottom: true,
      }),
    },
  },
  computed: {
    loadingDisplay() {
      return (
        this.$store.state.loading.show ||
        this.$store.state.loading.force ||
        this.$store.state.loading.transition
      );
    },
  },
};
</script>
