<template>
  <v-chip
    v-if="RequestStatus__c[value] && RequestStatus__c[value].labelClass"
    :class="RequestStatus__c[value].labelClass"
    small
  >
    {{ value }}
  </v-chip>
  <span v-else>{{ value }}</span>
</template>

<script>
import { RequestStatus__c } from '@/assets/js/objects/CDS_T_Request__c';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    RequestStatus__c,
  }),
};
</script>
