<template>
  <v-checkbox
    v-model="context.model"
    :label="context.label"
    v-bind="context.attributes"
    :persistent-hint="!context.attributes.disabled"
    class="mt-0 boolean-field"
    @blur="context.blurHandler"
    v-on="context.attributes.handler"
  />
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  watch: {
    context: {
      handler() {
        if (
          !this.context.attributes.disabled &&
          this.context.model !== true &&
          this.context.model !== false &&
          !this.context.attributes.notApplyDefaultValue
        ) {
          //初期値を設定する
          const { defaultValue } = this.context.attributes;
          if (typeof defaultValue === 'boolean') {
            this.$set(this.context, 'model', defaultValue);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.boolean-field.v-input--checkbox
  .v-input__control
  .v-input__slot
  label.v-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
