<template>
  <v-dialog
    v-if="!context.attributes.disabled"
    v-model="display"
    :width="dialogWidth"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="formattedValue"
        outlined
        clearable
        dense
        readonly
        hide-details="auto"
        :label="context.label"
        v-bind="context.attributes"
        v-on="on"
        @click:clear="clearHandler"
        @blur="context.blurHandler"
      ></v-text-field>
    </template>

    <v-card :id="context.name">
      <v-card-text class="px-0 py-0 text--primary">
        <v-tabs v-model="activeTab" fixed-tabs>
          <v-tab key="calendar">
            <slot name="dateIcon">
              日付
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              時間
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="editValueData.date"
              full-width
              class="rounded-t-0"
              @input="showTimePicker"
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer" eager>
            <div class="timePickerWrapper d-flex justify-center pa-2">
              <TimePicker
                v-model="editValueData.time"
                :active="display"
                :height="'280px'"
                :can-select-hour="context.attributes.canSelectHour !== false"
                :can-select-minute="
                  context.attributes.canSelectMinute !== false
                "
              ></TimePicker>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn
            v-if="!context.attributes.hideNow"
            color="green"
            outlined
            @click.native="nowHandler"
          >
            {{ nowText }}
          </v-btn>
          <v-btn
            color="green darken-1"
            class="white--text"
            depressed
            :disabled="dateSelected"
            @click="okHandler"
          >
            {{ okText }}
          </v-btn>
          <v-btn color="grey darken-1" outlined @click.native="clearHandler">
            {{ clearText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <disabled v-else :label="context.label" :value="formattedValue" />
</template>

<script>
import { parse } from 'date-fns';
import TimePicker from './time-picker.vue';
import disabled from './disabled';

const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00:00';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = '空欄にする';
const DEFAULT_OK_TEXT = '設定';
const DEFAULT_NOW_TEXT = '現在日時';
const DEFAULT_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss+09:00";
const DEFAULT_LOCALDATETIME_FORMAT = 'yyyy年MM月dd日(E) HH:mm';

export default {
  components: {
    TimePicker,
    disabled,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: 'HH:mm',
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    nowText: {
      type: String,
      default: DEFAULT_NOW_TEXT,
    },
  },
  data: () => ({
    // モーダル表示
    display: false,
    // モーダル内のタブ
    activeTab: 0,
    // デフォルトのフォーマット
    defaultDateTimeFormat: DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT,

    // 編集用のデータの実体
    editValueData: {
      date: null,
      time: null,
    },
  }),
  computed: {
    /**
     * オリジナルデータの編集等
     */

    // オリジナルデータ
    value: {
      get() {
        return this.context.model;
      },
      set(newValue) {
        this.$set(this.context, 'model', newValue);
      },
    },

    // 整形されたオリジナルデータ
    formattedValue() {
      return this.$dateFns.fnsFormat(
        this.value,
        '',
        DEFAULT_LOCALDATETIME_FORMAT,
      );
    },

    /**
     * 編集用データの編集等
     */

    // 編集用データ
    editValue: {
      get() {
        if (this.editValueData.date && this.editValueData.time) {
          // dateとtimeをあわせて返す
          let datetimeString =
            this.editValueData.date + ' ' + this.editValueData.time;
          if (this.editValueData.time.length === 5) {
            datetimeString += ':00';
          }
          const result = parse(
            datetimeString,
            this.defaultDateTimeFormat,
            new Date(),
          );
          return result;
        }
        return null;
      },
      set(newValue) {
        if (newValue) {
          // dateとtimeに分割してセット
          // date
          this.$set(
            this.editValueData,
            'date',
            this.$dateFns.fnsFormat(newValue, '', DEFAULT_DATE_FORMAT) ||
              DEFAULT_DATE,
          );
          // time
          this.$set(
            this.editValueData,
            'time',
            this.$dateFns.fnsFormat(newValue, '', DEFAULT_TIME_FORMAT) ||
              DEFAULT_TIME,
          );
        } else {
          // 値がない場合は初期値にする
          this.editValueData.date = DEFAULT_DATE;
          this.editValueData.time = DEFAULT_TIME;
        }
      },
    },

    // 日付が選択されているか
    dateSelected() {
      return !this.editValueData.date;
    },
  },

  watch: {
    display: {
      handler(to) {
        if (to) {
          // モーダルが開いた時に編集用の値にセットする
          this.editValue = this.value;
        } else {
          // モーダルが閉じた時にリセットする
          this.resetPicker();
        }
      },
    },
  },

  methods: {
    okHandler() {
      this.value = this.$dateFns.fnsFormat(
        this.editValue,
        '',
        DEFAULT_DATETIME_FORMAT,
      );
      this.resetPicker();
    },
    clearHandler() {
      this.value = null;
      this.resetPicker();
    },
    nowHandler() {
      this.value = this.$dateFns.fnsFormat(
        new Date(),
        '',
        DEFAULT_DATETIME_FORMAT,
      );
      this.resetPicker();
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
};
</script>
