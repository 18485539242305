<template>
  <v-icon v-bind="$attrs" :color="color">{{ icon }}</v-icon>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
    },
  },
  computed: {
    icon() {
      if (this.type === 'image') return 'mdi-image';
      if (this.type === 'video') return 'mdi-video';
      return 'mdi-file';
    },
    color() {
      if (this.type === 'image') return 'green';
      if (this.type === 'video') return 'orange';
      return 'bule';
    },
  },
};
</script>
