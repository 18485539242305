<template>
  <!-- 発令種別のラベルを生成するコンポーネント -->
  <v-chip label small :class="labelClass" :style="typeStyle">
    {{ typeShort }}
  </v-chip>
</template>

<script>
import { AnnounceType__c } from '@/assets/js/objects/CDS_T_EvacuationAdvisory__c';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    labelClass: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    source: {
      緊急安全確保: {
        style: AnnounceType__c['緊急安全確保'].labelStyle,
        short: AnnounceType__c['緊急安全確保'].short,
      },
      避難指示: {
        style: AnnounceType__c['避難指示'].labelStyle,
        short: AnnounceType__c['避難指示'].short,
      },
      高齢者等避難: {
        style: AnnounceType__c['高齢者等避難'].labelStyle,
        short: AnnounceType__c['高齢者等避難'].short,
      },
      警戒区域設定: {
        style: AnnounceType__c['警戒区域設定'].labelStyle,
        short: AnnounceType__c['警戒区域設定'].short,
      },
    },
  }),
  computed: {
    typeStyle() {
      return (this.source[this.value] || {}).style;
    },
    typeShort() {
      return (this.source[this.value] || {}).short;
    },
  },
};
</script>
