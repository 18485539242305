<template>
  <div>
    <v-badge
      bordered
      bottom
      dot
      offset-x="10"
      offset-y="10"
      :color="
        2 * 60 * 1000 > current - Date.parse(user.LastLoginDate)
          ? 'green'
          : 'grey'
      "
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-avatar
            color="primary"
            :size="size"
            :class="`elevation-${level}`"
            v-bind="attrs"
            v-on="on"
          >
            <v-img v-if="user.Avatar" :src="user.Avatar" />
            <span
              v-else-if="!!userNameFirst"
              class="font-weight-bold white--text"
            >
              {{ userNameFirst }}
            </span>
            <v-icon v-else dark>
              mdi-account
            </v-icon>
          </v-avatar>
        </template>
        <span>{{ user.Name }}</span>
      </v-tooltip>
    </v-badge>
  </div>
</template>

<script>
export default {
  name: 'Chat',

  props: {
    user: { type: Object, required: true },
    level: { type: Number, default: 0 },
    size: { type: String, default: '32' },
  },

  data: () => ({
    intervalId: null,
    current: Date.now(),
  }),

  computed: {
    // 最初の1文字
    userNameFirst() {
      return this.user?.Name?.slice(0, 1);
    },
  },

  mounted() {
    this.intervalId = setInterval(() => {
      this.current = Date.now();
    }, 30 * 1000);
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style scoped></style>
