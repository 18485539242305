<template>
  <v-card height="300" flat style="z-index: 2;">
    <v-snackbar
      :timeout="timeout"
      :value="snackbar"
      :color="color"
      multi-line
      absolute
      top
      text
    >
      <v-row>
        <v-icon :color="color">
          {{ icon }}
        </v-icon>
        <v-col>
          {{ body }}
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  props: {
    body: { type: String },
    icon: { type: String },
    color: { type: String },
    timeout: { type: Number, default: () => -1 },
  },
  watch: {
    snackbar(val) {
      if (!val) this.$emit('close', val);
    },
  },
  data: () => ({
    snackbar: true,
  }),
  methods: {},
};
</script>
<style scoped></style>
