<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{on}">
        <v-btn icon v-on="on" @click="dialog = true">
          <v-icon>mdi-chat-plus</v-icon>
        </v-btn>
      </template>
      <span>ルームを作成</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      scrollable
      :max-width="$vuetify.breakpoint.mdAndUp ? '1000' : '505'"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          ルームを作成
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="roomName"
            autofocus
            outlined
            dense
            hide-details
            label="ルーム名"
            class="my-2"
          />
          <user-selector v-model="selected" :is-active="dialog" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            @click="onCreateClick"
          >
            作成
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { gql } from 'graphql-tag';
import { format } from 'date-fns';
import UserSelector from './UserSelector.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Chat',

  components: { UserSelector },

  data: () => ({
    dialog: false,
    selected: [],
    user: [],
    roomName: '',
    loading: false,
  }),

  watch: {
    dialog(to, from) {
      if (to !== from) {
        this.$emit('updated-dialog', to);
      }
      if (!to && from) {
        this.roomName = '';
      }
    },
  },

  methods: {
    async onCreateClick() {
      if (!this.roomName) {
        this.openErrorSnackBar({
          message: 'ルーム名は必須です。',
        });
        return;
      }
      if (this.selected.length === 0) {
        this.openErrorSnackBar({
          message: 'ユーザを選択してください。',
        });
        return;
      }
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.createChatRoom()]),
      );
    },
    /**
     * ルームを作成する
     */
    async createChatRoom() {
      try {
        const { Id } = (
          await this.$apollo.mutate({
            mutation: /* GraphQL */ gql`
              mutation createChatRoom($data: ChatRoomCreateInput!) {
                createChatRoom(data: $data) {
                  Id
                  Name
                  CDS_T_Disaster__c
                }
              }
            `,
            variables: {
              data: {
                Name: this.roomName,
                CreatedDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                CDS_T_Disaster__c: this.$store.state.disaster.disaster.Id,
              },
            },
          })
        ).data.createChatRoom;
        try {
          // ルームに参加者を追加
          // 重複排除したユーザId
          const userIds = [
            ...new Set([
              ...this.selected.map((s) => s.Id),
              // 自分を追加
              this.$store.state.user.user.Id,
            ]),
          ];
          await Promise.all(
            userIds.map(async (userId) => {
              await this.$apollo.mutate({
                mutation: /* GraphQL */ gql`
                  mutation updateChatRoomUser(
                    $Data: ChatRoomOnUserCreateInput!
                  ) {
                    createChatRoomOnUser(data: $Data) {
                      Id
                      chatRoomId
                      userId
                    }
                  }
                `,
                variables: {
                  Data: {
                    ChatRoom: {
                      connect: {
                        Id,
                      },
                    },
                    User: {
                      connect: {
                        Id: userId,
                      },
                    },
                  },
                },
              });
            }),
          );
        } catch (error) {
          console.error('ルームの参加者追加に失敗');
          console.error(error);
          // ルームの参加者追加に失敗したらルームを削除する
          await this.$apollo.mutate({
            mutation: /* GraphQL */ gql`
              mutation RollBack($Id: String) {
                deleteChatRoom(where: { Id: $Id }) {
                  Id
                }
              }
            `,
            variables: { Id },
          });
          throw new Error('ルームの参加者追加に失敗しました。');
        }
        this.selected = [];
        this.roomName = '';
        await this.$nextTick();
        this.dialog = false;
        this.$emit('created', Id);
      } catch (error) {
        throw new Error('ルームの作成に失敗しました。' + error);
      }
    },

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),
  },
};
</script>
