<template>
  <div style="height: 100%;">
    <div
      :style="{ 'min-height': '35px' }"
      class="d-flex align-center text--primary"
    >
      <div class="d-flex align-center align-self-stretch">
        <slot name="bar_tab"></slot>
      </div>
      <v-spacer class="tab-spacer align-self-end" />
      <div class="d-flex align-center align-self-stretch">
        <BarCardTab class="px-1">
          <slot name="bar_left"></slot>
          <v-tooltip
            v-if="showReload"
            top
            :disabled="$vuetify.breakpoint.smAndDown"
          >
            <template #activator="{ on: onTooltip }">
              <v-btn icon v-on="{ ...onTooltip }" @click.stop="$emit('reload')">
                <v-icon>
                  mdi-reload
                </v-icon>
              </v-btn>
            </template>
            <span>更新</span>
          </v-tooltip>
          <v-tooltip
            v-if="barClick"
            top
            :disabled="$vuetify.breakpoint.smAndDown"
          >
            <template #activator="{ on: onTooltip }">
              <v-btn icon v-on="{ ...onTooltip }" @click.stop="barClickFunc">
                <v-icon>
                  mdi-chevron-double-right
                </v-icon>
              </v-btn>
            </template>
            <span>詳細を確認する</span>
          </v-tooltip>
        </BarCardTab>
      </div>
    </div>
    <v-card
      elevation="1"
      outlined
      class="rounded-t-0"
      style="border-top: 0; height: calc(100% - 35px);"
    >
      <v-progress-linear
        :active="loading"
        indeterminate
        color="teal"
        absolute
      ></v-progress-linear>
      <div>
        <slot></slot>
      </div>
    </v-card>
  </div>
</template>

<script>
import BarCardTab from './BarCardTab.vue';

export default {
  components: { BarCardTab },
  props: {
    barProps: {
      type: Object,
      default: () => ({}),
    },
    // on
    barOn: {
      type: Object,
      default: null,
    },
    barClick: {
      type: Function,
      default: null,
    },
    // ローディング
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    showReload: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    barClickFunc() {
      const emptyFunc = () => {
        /* */
      };
      return this.barClick || emptyFunc;
    },
  },
  methods: {
    aaa() {
      console.log('xxxx');
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
// .bar-card-tab {
//   display: flex;
//   align-items: center;
//   align-self: stretch;
//   background: white;
//   border-top-left-radius: 5px;
//   border-top-right-radius: 5px;
//   border-top: 1px solid #cfd8dc;
//   border-left: 1px solid #cfd8dc;
//   border-right: 1px solid #cfd8dc;
// }
</style>

<style lang="scss" scoped>
.tab-spacer {
  height: 1px;
  border-bottom: 1px solid #e0e0e0;
}
</style>
