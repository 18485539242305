<template>
  <v-app-bar
    id="dis-app-bar"
    :height="$vuetify.breakpoint.xs ? '85px' : '47px'"
    :max-height="$vuetify.breakpoint.xs ? '85px' : '47px'"
    fixed
    elevate-on-scroll
    style="top: 0; position: sticky; -webkit-position: sticky;"
    color="white"
    class="d-print-none"
  >
    <!-- ローディング -->
    <Loading progress :linear-props="{ top: true }" />
    <!-- <Loading progress /> -->
    <div style="position: relative; width: 100%;">
      <div
        :class="{
          'd-flex': !isMobile,
          'align-center': true,
        }"
      >
        <div class="d-flex flex-grow-1">
          <!-- 災害モード表示 -->
          <Disaster />
          <v-spacer></v-spacer>
          <!-- 検索 -->
          <Search />
        </div>
        <div class="d-flex">
          <div class="d-flex flex-grow-1 overflow-x-auto align-center">
            <MegaMenu
              v-for="section in filteredSections"
              :key="section.Id"
              :section="section"
              class="d-block"
            />
          </div>
          <div class="d-flex flex-grow-0 flex-shrink-0">
            <!-- チャット -->
            <Chat />
            <!-- 通知表示 -->
            <Notice />
          </div>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import Disaster from '@/components/core/parts/Disaster';
import Notice from '@/components/core/parts/Notice';
import Chat from '@/components/Chat';
import MegaMenu from '@/components/core/MegaMenu';
import Search from '@/components/core/parts/Search';
import Loading from '@/components/core/Loading';

export default {
  name: 'DashboardCoreAppBar',

  components: {
    Disaster,
    Notice,
    Chat,
    MegaMenu,
    Search,
    Loading,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),

  computed: {
    isAdmin() {
      return this.$store.state.user.user.isAdmin;
    },
    // 災害関連セクションをフィルタ
    filteredSections() {
      return (
        this.$store.state.menu.menusection
          ?.filter((section) => section.IsDisaster__c)
          .map((section) => section) || []
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
