<template>
  <v-card flat max-width="500">
    <v-card-title>詳細</v-card-title>
    <v-card-text>
      <v-textarea :value="value" @input="(ev) => $emit('input', ev)" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
  data: () => ({}),
  async mounted() {},
  methods: {},
};
</script>
<style scoped></style>
