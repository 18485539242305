<template>
  <v-row no-gutters>
    <div class="d-flex flex-grow-1">
      <div v-if="showbackarrow" class="d-flex align-center">
        <v-btn icon @click="$transition.back()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <v-row no-gutters>
        <v-col cols="auto" align-self="center" class="pr-3 pb-2">
          <v-badge
            :value="displayObject.badge"
            :color="displayObject.badgeColor"
            :icon="displayObject.badge"
            right
            bottom
            bordered
            overlap
          >
            <v-icon large>
              {{ displayObject.icon }}
            </v-icon>
          </v-badge>
        </v-col>
        <v-col>
          <div class="text-sm-h6 text-body-1">
            {{ displayObject.title }}
          </div>
          <div class="text-caption">{{ displayObject.subtitle }}</div>
        </v-col>
      </v-row>
    </div>
    <template v-if="($slots.default || $scopedSlots.default)">
      <v-spacer />
      <div class="d-flex align-center">
        <slot />
      </div>
    </template>
  </v-row>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    badge: {
      type: String,
      default: null,
    },
    badgeColor: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    doUpdate: {
      default: null,
    },
    showbackarrow: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    menuItem: {},
  }),
  computed: {
    displayObject() {
      return {
        icon: this.icon || this.menuItem.Icon__c || 'mdi-home',
        badge: this.badge,
        badgeColor: this.badgeColor || 'red',
        title: this.title || this.menuItem.Name || '',
        subtitle: this.subtitle || this.menuItem.Hint__c || '',
        ...(this.menuItem.PageHeaderOption__c &&
          JSON.parse(this.menuItem.PageHeaderOption__c)),
      };
    },
  },

  watch: {
    doUpdate: {
      async handler() {
        await this.loadMenuItem();
      },
      deep: true,
    },
  },
  async created() {
    await this.loadMenuItem();
  },
  methods: {
    // ページURLから対応するメニューアイテムを取得
    async loadMenuItem() {
      const item = await this.$util.getPageHeaderMenuItem();
      this.$set(this, 'menuItem', item || {});
    },
  },
};
</script>

<style>
#bottomNavigation {
  position: fixed;
  height: 56px;
  transform: none;
  z-index: 5;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  width: 100%;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  align-items: center;
}

#bottomNavigation button:not(.v-btn--fab) {
  box-shadow: none;
  max-width: 168px;
  min-width: 80px;
}
</style>
