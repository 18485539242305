<template>
  <v-dialog
    v-if="!context.attributes.disabled"
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="displayDate"
        :label="context.label"
        readonly
        dense
        outlined
        clearable
        hide-details="auto"
        @click:clear="date = null"
        @blur="context.blurHandler"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable>
      <v-spacer></v-spacer>
      <v-btn text @click="modal = false">Cancel</v-btn>
      <v-btn color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
  <disabled
    v-else
    :label="context.label"
    :value="displayDate"
    :displayFormat="context.attributes.displayFormat"
  />
</template>

<script>
import disabled from './disabled';

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    modal: false,
  }),
  computed: {
    date: {
      get() {
        return this.context.model;
      },
      set(value) {
        this.$set(this.context, 'model', value);
      },
    },
    // 表示用フォーマット
    displayDate() {
      return this.$dateFns.fnsFormat(
        this.context.model,
        '',
        'yyyy年MM月dd日(E)',
      );
    },
  },
  components: {
    disabled,
  },
};
</script>
