<template>
  <v-menu
    v-model="megamenu"
    tile
    :close-on-content-click="false"
    offset-y
    nudge-bottom="3"
    min-width="100%"
    content-class="elevation-0"
  >
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown">
        <template #activator="{ on: onTooltip }">
          <v-btn fab text small v-on="{ ...onMenu, ...onTooltip }">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>検索</span>
      </v-tooltip>
    </template>

    <v-card tile outlined elevation="0">
      <div class="pa-5">
        <div class="search-close-button-area pa-4 d-none d-md-block">
          <v-btn icon @click="megamenu = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <v-row justify="center">
          <v-col cols="12" md="6" xl="4">
            <div>
              <SearchForm
                v-model="searchForm.value"
                :form-name="searchForm.name"
                column="1"
                @submit-raw="handleOnSubmitSearch"
              />
            </div>
            <div class="d-flex justify-center">
              <v-btn color="primary" outlined @click="handleClickSearch">
                <v-icon left>
                  mdi-magnify
                </v-icon>
                <span>
                  検索
                </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import SearchForm from './SearchForm.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    SearchForm,
  },

  data: () => ({
    searchForm: {
      name: 'GlobalSearchFormOnAppBar',
      value: {},
    },
    sectionId: 'search',
  }),

  computed: {
    megamenu: {
      get() {
        if (!this.sectionId) return false;
        return this.$store.state.menu.activeMenues.includes(this.sectionId);
      },
      set(newValue) {
        if (!this.sectionId) return;
        this.$store.dispatch('menu/setActiveMenues', {
          name: this.sectionId,
          value: newValue,
        });
      },
    },
  },

  methods: {
    // ボタン押下時
    handleClickSearch() {
      this.$formulate.submit(this.searchForm.name);
    },

    // フォームsubmit時
    async handleOnSubmitSearch(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }

        this.toSearchPage();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    // 検索ページに遷移
    async toSearchPage() {
      // メニューを非表示
      this.megamenu = false;
      await this.$nextTick();
      // ページ遷移
      this.$transition.to(
        'CDS_VF_Search',
        {
          search: this.searchForm.value,
        },
        {
          notApplyRetUrl: true,
        },
      );
    },

    /******** 共通 ********/

    ...mapActions('snackbar', ['saveFail']),
  },
};
</script>

<style lang="scss" scoped>
.search-close-button-area {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
