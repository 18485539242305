<template>
  <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown">
    <template #activator="{ on: onTooltip }">
      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template #activator="{ attrs, on: onMenu }">
          <v-btn
            min-width="0"
            text
            v-bind="attrs"
            class="mr-n3"
            small
            :fab="$vuetify.breakpoint.smAndDown"
            v-on="{ ...onMenu, ...onTooltip }"
          >
            <v-icon>mdi-account</v-icon>
            <div class="d-none d-md-inline text-caption ml-2">
              {{ $organization.Name }} {{ $user.Name }}
              <template v-if="isActing">
                (代)
              </template>
            </div>
          </v-btn>
        </template>
        <v-card width="250px">
          <v-list dense min-width="170px" class="py-0 text-center">
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="5" class="text-end">
                    <v-avatar color="indigo">
                      <v-icon dark>
                        mdi-account
                      </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="7" class="text-start">
                    <v-list-item-subtitle>
                      {{ $organization.Name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="isActing">
                      <span class="red--text">代行ログイン中</span>
                    </v-list-item-subtitle>
                    <v-list-item-title>{{ $user.Name }}</v-list-item-title>
                    <v-list-item-title>{{ $user.Tel__c }}</v-list-item-title>
                  </v-col>
                  <v-col cols="12" align="center">
                    <v-btn outlined small @click="onClickUserEdit">
                      ユーザ情報編集
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <template v-if="canActing">
              <v-divider />
              <v-btn
                depressed
                class="ma-2"
                @click="$transition.to('CDS_VF_SelectUser')"
              >
                <v-icon left>
                  mdi-account-convert
                </v-icon>
                代行ログイン
              </v-btn>
              <v-btn
                v-if="isActing"
                depressed
                class="ma-2"
                @click="onClickActingRelease"
              >
                <v-icon left>
                  mdi-account-remove
                </v-icon>
                代行ログイン解除
              </v-btn>
            </template>
            <v-divider />
            <v-btn outlined small class="ma-4" @click="onClickLogout">
              <v-icon left>
                mdi-logout
              </v-icon>
              ログアウト
            </v-btn>
          </v-list>
        </v-card>
      </v-menu>
    </template>
    <span>ユーザ情報</span>
  </v-tooltip>
</template>

<script>
export default {
  data: () => ({
    userMenu: [],
  }),
  computed: {
    $user() {
      return this.$store.state.user.user;
    },
    $organization() {
      return this.$store.state.user.organization;
    },
    // 代行ログイン可能か
    canActing() {
      return !!this.$store.state.user.realUser.CanActing__c;
    },
    // 代行ログイン中
    isActing() {
      return this.$store.getters['user/isActing'];
    },
  },
  methods: {
    // 代行ログイン解除
    async onClickActingRelease() {
      const realUser = this.$store.state.user.realUser;

      // storeに設定
      await this.$store.dispatch('user/setUserId', realUser.Id);

      // フォールバック
      // ページをリロード
      window.location.href = this.$transition.fallbackPage;
    },
    // ユーザ情報編集
    onClickUserEdit() {
      this.$transition.to(
        'CDS_VF_UserSelfDetail',
        {},
        {
          notApplyRetUrl: true,
        },
      );
    },
    // ログアウト
    async onClickLogout() {
      this.$store.dispatch('disaster/setDisaster');
      this.$store.dispatch('user/erase');
      await this.$cognito.logout();
      window.location.href = '/login';
    },
  },
};
</script>
