<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <LazyLoad
            v-for="{ object, attachments } in value"
            :key="object.Id"
            :lazyProps="{
              options: { threshold: 0.5 },
              'min-height': '50',
              transition: 'fade-transition',
            }"
          >
            <div>
              <v-card-title class="pb-0">{{ object.Name }}</v-card-title>
              <v-container fluid class="">
                <v-row>
                  <v-col
                    v-for="d in attachments"
                    :key="d.Id"
                    class="d-flex child-flex"
                    cols="4"
                    sm="3"
                    xl="2"
                    @click="openDialog({ object, d })"
                  >
                    <!-- 画像表示部 -->
                    <v-card flat tile class="d-flex">
                      <v-img
                        v-if="d.Type.split('/')[0] === 'image'"
                        :src="d.src"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template #placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            />
                          </v-row>
                        </template>
                        <v-card-title>
                          <v-icon dark>
                            mdi-image
                          </v-icon>
                        </v-card-title>
                      </v-img>
                      <v-img
                        v-if="d.Type.split('/')[0] === 'video'"
                        :src="null"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <div
                          style="height: 100%; over-flow: hidden;"
                          class="d-flex"
                        >
                          <video
                            :src="d.src"
                            style="
                              width: 100%;
                              height: 100%;
                              position: absolute;
                            "
                          ></video>
                          <v-card-title style="position: absolute;">
                            <v-icon dark>
                              mdi-video
                            </v-icon>
                          </v-card-title>
                        </div>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider></v-divider>
            </div>
          </LazyLoad>
        </v-card>
      </v-col>
    </v-row>
    <!-- モーダル -->
    <v-dialog v-model="dialog.show" max-width="1680">
      <v-card v-if="dialog.data && dialog.data.d && dialog.data.d.src">
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" lg="9" class="d-flex justify-center">
              <v-img
                v-if="dialog.data.d.Type.split('/')[0] === 'image'"
                :src="dialog.data.d.src"
                width="100%"
                max-height="800px"
                contain
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
              </v-img>
              <video
                v-if="dialog.data.d.Type.split('/')[0] === 'video'"
                :src="dialog.data.d.src"
                style="width: 100%; max-height: 600px;"
                controls
              ></video>
              <div />
            </v-col>
            <v-col cols="12" lg="3">
              <v-row no-gutters class="h100">
                <v-col cols="12">
                  <slot v-bind="dialog.data"></slot>
                  <v-list>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-file</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>ファイルタイプ</v-list-item-title>
                        <v-list-item-subtitle style="white-space: pre-wrap;">
                          {{ dialog.data.d.Type }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-database</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>ファイルサイズ</v-list-item-title>
                        <v-list-item-subtitle style="white-space: pre-wrap;">
                          {{ dialog.data.d.Size | filesize }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" align-self="end" class="d-flex justify-end">
                  <v-btn
                    v-if="dialog.data.object"
                    color="primary"
                    @click="$emit('to-detail', dialog.data.object)"
                  >
                    詳細へ
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-btn
              icon
              absolute
              dark
              right
              style="background-color: rgba(0, 0, 0, 0.3) !important;"
              @click="dialog.show = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LazyLoad from '@/components/common/LazyLoad';

export default {
  name: 'ImagePanel',
  props: {
    value: {
      type: Array,
      default: () => [],
      /*
      example:
      [
        {
          "object": {
            "attributes": {
              "type": "CDS_T_DamageReport__c",
              "url": "/services/data/v49.0/sobjects/CDS_T_DamageReport__c/a0A5D000003x2d6UAA"
            },
            "Id": "a0A5D000003x2d6UAA",
            "Name": "テスト事案3"
          },
          "attachments": [
            {
              "attributes": {
                "type": "ContentVersion",
                "url": "/services/data/v49.0/sobjects/ContentVersion/0685D000000e7h5QAA"
              },
              "Id": "0685D000000e7h5QAA",
              "ContentSize": 1065529,
              "src": "/sfc/servlet.shepherd/version/download/0685D000000e7h5QAA",
              "type": "image/jpeg"
            },
            {
              "attributes": {
                "type": "ContentVersion",
                "url": "/services/data/v49.0/sobjects/ContentVersion/0685D000000e7hAQAQ"
              },
              "Id": "0685D000000e7hAQAQ",
              "ContentSize": 1201550,
              "src": "/sfc/servlet.shepherd/version/download/0685D000000e7hAQAQ",
              "type": "image/jpeg"
            }
          ]
        }
      ]
      */
    },
  },
  data: () => ({
    dialog: {
      show: false,
      data: {},
    },
  }),
  methods: {
    openDialog(object) {
      this.dialog.data = object;
      this.dialog.show = true;
    },
  },
  components: {
    LazyLoad,
  },
};
</script>
