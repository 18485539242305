<template functional>
  <div class="text-center">
    <v-overlay :value="true" :z-index="999" :opacity="0.5" color="white">
      <v-progress-circular indeterminate color="primary" :size="130" :width="2">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { VOverlay, VProgressCircular } from 'vuetify/lib';

Vue.component('VOverlay', VOverlay);
Vue.component('VProgressCircular', VProgressCircular);

export default {
  name: 'CircularLoading',
};
</script>
