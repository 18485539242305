<template>
  <div
    ref="time-number-selector"
    :class="{
      'time-number-selector': true,
      'overflow-y-auto': true,
      disabled: !canEdit,
    }"
    :style="{
      height,
      width,
    }"
  >
    <div
      v-if="label"
      class="select-label text-center orange darken-1 white--text py-1"
    >
      {{ label }}
    </div>
    <div
      v-for="item in items"
      :key="item"
      class="select-number text-center py-1"
      :class="{ selected: item === editValue, disabled: !canEdit }"
      @click="handleClickItem(item)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeNumberSelector',
  props: {
    value: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '200px',
    },
    width: {
      type: String,
      default: '70px',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 59,
    },
    step: {
      type: Number,
      default: 1,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    editValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    items() {
      let numbers = [];
      for (let i = this.min; i <= this.max; i = i + this.step) {
        numbers.push(i);
      }
      if (numbers.length === 0) return numbers;
      const maxNum = Math.max(...numbers);
      // 桁数
      const digits = maxNum.toString().length;
      // 0埋め
      const result = numbers.map((n) => _.padStart(n, digits, '0'));
      return result;
    },
  },
  watch: {
    active(to, from) {
      if (to !== from) {
        this.scrollToTop();
      }
    },
  },
  methods: {
    handleClickItem(item) {
      if (!this.canEdit) return;
      this.editValue = item;
    },
    scrollToTop() {
      this.$refs['time-number-selector'].scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.time-number-selector {
  border: 1px solid #78909c;

  .select-number {
    font-size: 16px;

    &.disabled {
      color: gray;
      background: darkgray;
      cursor: not-allowed;
    }

    &:not(.disabled) {
      cursor: pointer;

      &.selected {
        color: white;
        background: #7cb342;
      }

      &:not(.selected):hover {
        background: #eceff1;
      }
    }
  }
}
</style>
