<template>
  <v-text-field
    v-if="!context.attributes.disabled"
    v-model="context.model"
    outlined
    dense
    hide-details="auto"
    @blur="context.blurHandler"
    :label="context.label"
    v-bind="context.attributes"
    v-on="context.attributes.handler"
  />
  <disabled v-else :label="context.label" :value="context.model" />
</template>

<script>
import disabled from './disabled';
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    disabled,
  },
};
</script>
