<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card>
      <v-card-title>
        <slot name="title">
          <span class="text-subtitle-1">{{ message }}</span>
        </slot>
      </v-card-title>
      <slot name="inner"></slot>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="action">
          <v-btn
            color="primary"
            text
            v-bind="noButtonProps"
            class="ml-4"
            @click="onClickNo"
          >
            {{ noButtonText }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            v-bind="yesButtonProps"
            class="ml-4"
            @click="onClickYes"
          >
            {{ yesButtonText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'よろしいですか？',
    },
    yesButtonText: {
      type: String,
      default: 'OK',
    },
    yesButtonProps: {
      type: Object,
      default: () => ({}),
    },
    noButtonText: {
      type: String,
      default: 'キャンセル',
    },
    noButtonProps: {
      type: Object,
      default: () => ({}),
    },
    maxWidth: {
      type: [String, Number],
      default: '500px',
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    onClickYes: function () {
      this.dialog = false;
      this.$emit('on-click-yes');
      this.$emit('on-decided', true);
    },
    onClickNo: function () {
      this.dialog = false;
      this.$emit('on-click-no');
      this.$emit('on-decided', false);
    },
  },
};
</script>
