<template>
  <v-select
    v-if="!context.attributes.disabled"
    v-model="_value"
    outlined
    clearable
    dense
    multiple
    item-text="label"
    item-value="value"
    hide-details="auto"
    :label="context.label"
    v-bind="context.attributes"
    persistent-hint
    @blur="context.blurHandler"
    v-on="context.attributes.handler"
  >
    <template #prepend-item>
      <v-list-item ripple dense @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="_value.length > 0 ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            すべて選択
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
  <disabled v-else :label="context.label" :value="_dispValue" />
</template>

<script>
import disabled from './disabled';

export default {
  components: {
    disabled,
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  computed: {
    _value: {
      get() {
        return (this.context.model && this.context.model.split(';')) || [];
      },
      set(val) {
        // eslint-disable-next-line vue/no-mutating-props
        this.context.model = val.join(';');
      },
    },
    _dispValue: {
      get() {
        // labelの値を表示する
        const items = this?.context?.attributes?.items;
        const values = (this.context.model || '').split(';');
        if (!items) return this.context.model;

        return values
          .map((v) => {
            const label = items.find((i) => i.value === v)?.label || v;
            return label;
          })
          .join(';');
      },
    },

    items() {
      return this.context.attributes.items;
    },

    likesAllItem() {
      return this._value.length === this.items.length;
    },
    likesSomeItem() {
      return this._value.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllItem) return 'mdi-close-box';
      if (this.likesSomeItem) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllItem) {
          this._value = [];
        } else {
          this._value = this.items.map((i) => i.value);
        }
      });
    },
  },
};
</script>
