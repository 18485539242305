<template>
  <v-snackbar
    v-model="snackbar"
    v-bind="$store.state.snackbar.props"
    class="pb-12"
    @click.native="snackbar = false"
  >
    {{ $store.state.snackbar.message }}
    <template #action="{ attrs }">
      <v-btn
        v-if="$store.state.snackbar.closable"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        閉じる
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar.value;
      },
      set(value) {
        this.$store.dispatch('snackbar/setSnackBar', !!value);
      },
    },
  },
};
</script>
