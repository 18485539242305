<template>
  <div v-if="!fullscreenDisplay">
    <slot />
  </div>
  <v-dialog
    v-else
    v-model="fab"
    eager
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: 'ChatMainPanel',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fab: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    fullscreenDisplay() {
      if (this.fullscreen) return true;
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
