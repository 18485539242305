<template>
  <v-menu
    bottom
    left
    offset-y
    origin="top right"
    transition="scale-transition"
    max-width="340px"
    min-width="300px"
  >
    <template #activator="{ on: onMenu }">
      <v-row align="center" no-gutters>
        <template v-if="$store.getters['disaster/exist']">
          <div class="d-flex align-center disasterPanel" v-on="{ ...onMenu }">
            <v-btn
              tile
              depressed
              dark
              :color="disasterColor"
              style="padding-left: 8px; padding-right: 8px; min-width: 50px;"
            >
              {{ $disaster.DisasterType__c }}
            </v-btn>
            <v-icon v-if="isFujisanConnected" color="red" class="ml-2 mr-n1">
              mdi-power-plug-outline
            </v-icon>
            <span
              class="text-subtitle-1 ml-2 text-truncate"
              :style="{
                'max-width': disasterWidth,
              }"
              >{{ $disaster.Name }}</span
            >
          </div>
        </template>
        <template v-else>
          <v-btn outlined>
            災害選択
          </v-btn>
        </template>
      </v-row>
    </template>
    <v-card class="detail">
      <v-list dense class="py-0">
        <DisasterDetail />
        <v-divider />
        <v-btn
          depressed
          class="ma-2"
          @click="
            $transition.to('CDS_VF_SelectDisaster', { autoSelect: false })
          "
        >
          <v-icon left>
            mdi-cached
          </v-icon>
          災害変更
        </v-btn>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import DisasterDetail from '@/components/core/parts/DisasterDetail';
export default {
  components: {
    DisasterDetail,
  },
  computed: {
    $disaster() {
      return this.$store.state.disaster.disaster;
    },
    disasterColor: function () {
      switch (this.$store.state.disaster.disaster.DisasterType__c) {
        case '実災害':
          return 'red';
        case '訓練':
          return 'green';
        default:
          return '';
      }
    },
    disasterWidth: function () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '230px';
        case 'sm':
          return '300px';
        case 'md':
          return '500px';
        default:
          return '1000px';
      }
    },
    isFujisanConnected() {
      return this.$store.getters['disaster/isFujisanConnected'];
    },
  },
  methods: {
    toSelect() {
      this.$transition.to('CDS_VF_SelectDisaster');
    },
  },
};
</script>

<style scoped>
.disasterPanel {
  cursor: pointer;
}
</style>
