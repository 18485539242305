<template>
  <v-menu bottom left offset-y origin="top right" transition="scale-transition">
    <template #activator="{ attrs, on: onMenu }">
      <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown">
        <template #activator="{ on: onTooltip }">
          <v-btn
            min-width="0"
            fab
            text
            small
            v-bind="attrs"
            v-on="{ ...onMenu, ...onTooltip }"
          >
            <v-badge
              v-if="notifications.length > 0"
              color="red"
              overlap
              bordered
            >
              <template #badge>
                <span>{{ noticeBadgeContent }}</span>
              </template>
              <v-icon>mdi-bell</v-icon>
            </v-badge>
            <v-icon v-else>
              mdi-bell
            </v-icon>
          </v-btn>
        </template>
        <span>お知らせ</span>
      </v-tooltip>
    </template>

    <v-list dense min-width="180px" class="py-0">
      <v-subheader>お知らせ</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item v-for="(notification, i) in notifications" :key="i">
          <v-list-item-content
            @click="$transition.to(`CDS_VF_NoticeDetail?id=${notification.Id}`)"
          >
            <v-list-item-title>
              {{ notification.Name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-divider />
      <v-list-item-group color="primary">
        <v-list-item>
          <v-list-item-icon>
            <v-icon small>
              mdi-menu
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="$transition.to('CDS_VF_NoticeList')">
            <v-list-item-title>すべて表示</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    notifications: [],
    polling: null,
  }),
  computed: {
    noticeBadgeContent() {
      const size = this.notifications?.length;
      if (size > 9) {
        return '9+';
      }
      return size;
    },
  },
  async mounted() {
    await this.loadNotice();
    this.pollData();
  },
  beforeDestroy() {
    if (this.polling) clearInterval(this.polling);
  },
  methods: {
    pollData() {
      if (this.polling) clearInterval(this.polling);
      this.polling = setInterval(async () => {
        await this.loadNotice();
      }, 30000);
    },
    async loadNotice() {
      this.notifications = await this.$con.invoke({
        controller: 'CDS_CTR_Common',
        method: 'getNotice',
        params: {
          organizationId: this.$store.state.user.organization.Id,
          disasterId: this.$store.state.disaster.disaster.Id,
        },
      });
      this.notifications = this.notifications || [];
    },
  },
};
</script>
