<template>
  <div
    v-show="enable"
    id="chat-component-wrapper"
    class="ml-2"
    style="z-index: 4"
  >
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn fab text small @click="() => (fab = !fab)" v-on="on">
          <v-badge :value="hasNewMessage" color="error" dot overlap>
            <v-icon> mdi-chat </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span>チャットを開始</span>
    </v-tooltip>
    <MainPanel v-show="fab" v-model="fab" :fullscreen="fullscreen">
      <MainWindow
        v-model="fab"
        :fullscreen.sync="fullscreen"
        :prop-room-id="roomId"
        @update:prop-room-id="roomId = $event"
        @has-new-message="hasNewMessage = $event"
      />
    </MainPanel>
  </div>
</template>

<script>
import MainWindow from './MainWindow.vue';
import MainPanel from './MainPanel.vue';

export default {
  name: 'Chat',

  components: {
    MainPanel,
    MainWindow,
  },

  props: {},

  data: () => ({
    fab: false,
    fullscreen: false,
    hasNewMessage: false,
    roomId: '',
    notices: [],
  }),

  computed: {
    enable() {
      return !!this.$store.state.disaster.disaster.Id;
    },
  },

  watch: {
    hasNewMessage: {
      async handler(to, from) {
        if (to && !from) {
          const n = await this.$notification.post(
            '[防災システム] 新着メッセージがあります。',
          );
          if (n) {
            this.notices = [...this.notices, n];
          }
        } else if (!to && from) {
          this.$notification.clear(this.notices);
          this.notices = [];
        }
      },
    },
  },
};
</script>

<style scoped></style>
