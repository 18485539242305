<template>
  <div class="mt-n5 pa-2">
    <div class="text-caption">
      {{ context.label }}
    </div>
    <v-dialog v-model="dialog">
      <template #activator="{on, attrs}">
        <v-btn
          v-bind="attrs"
          :disabled="context.attributes.disabled"
          depressed
          color="blue-grey lighten-5"
          v-on="on"
        >
          位置情報を入力
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          位置情報の入力
          <small class="mx-3 deep-orange--text">
            位置を選択して「OK」ボタンをクリックしてください。
          </small>
        </v-card-title>
        <v-divider></v-divider>
        <div v-if="dialog">
          <MapComponent
            :context="context"
            :can-draw="false"
            :show-g-i-s-button="false"
          ></MapComponent>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import MapComponent from '@/googlemap/googlemap';
import MapComponent from '@/arcGIS/esrimap';

export default {
  components: {
    MapComponent,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>
