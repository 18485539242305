<template>
  <div style="position: relative;">
    <MapArea :context="context" :can-draw="true"></MapArea>
  </div>
</template>
<script>
// import MapArea from '@/googlemap/googlemap';
import MapArea from '@/arcGIS/esrimap';

export default {
  components: {
    MapArea,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
};
</script>
