<template>
  <v-card
    class="mx-auto"
    style="height: auto; z-index: 1;"
    max-width="300"
    v-show="enable"
  >
    <v-system-bar color="indigo darken-2" dark>
      <v-spacer></v-spacer>

      <v-icon @click="showToolBox = false">mdi-window-minimize</v-icon>

      <v-icon @click="showToolBox = true">mdi-window-maximize</v-icon>
    </v-system-bar>
    <!-- モード選択ボタン -->
    <v-scale-transition>
      <div v-show="showToolBox">
        <controlAccordion
          name="tool"
          title="ツール"
          :useAccordion="useAccordion"
          :openFields="openedAccordion"
          @toggle="openedAccordion = $event"
        >
          <v-card-actions>
            <v-btn-toggle mandatory dense v-model="mode">
              <v-btn
                icon
                :key="'tool' + i"
                v-for="({ icon }, i) in drawingModes"
              >
                <v-icon>{{ icon }}</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-card-actions>
        </controlAccordion>
        <!-- 色選択 -->
        <controlAccordion
          v-if="useColor"
          name="color"
          title="色"
          :useAccordion="useAccordion"
          :openFields="openedAccordion"
          @toggle="openedAccordion = $event"
        >
          <div
            @click="showColor = !showColor"
            id="colorSample"
            class="ma-3"
            :style="{ background: color }"
          />
          <v-scale-transition>
            <FormulateInput
              v-if="showColor"
              type="color"
              v-model="color"
              @input="showColor = false"
            />
          </v-scale-transition>
        </controlAccordion>
        <controlAccordion
          name="edit"
          title="編集"
          :useAccordion="useAccordion"
          :openFields="openedAccordion"
          @toggle="openedAccordion = $event"
        >
          <v-card-actions>
            <v-btn icon @click="doDelete()">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <v-btn icon @click="drawController.undo()">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
            <v-btn icon @click="drawController.redo()">
              <v-icon>mdi-redo</v-icon>
            </v-btn>
          </v-card-actions>
        </controlAccordion>
        <controlAccordion
          name="search"
          title="住所検索"
          :useAccordion="useAccordion"
          :openFields="openedAccordion"
          @toggle="openedAccordion = $event"
        >
          <v-text-field
            prepend-inner-icon="mdi-map-search"
            clearable
            placeholder="場所を入力してください"
            class="mx-3 my-2"
            dense
            ref="map-search"
            hide-details="auto"
          />
        </controlAccordion>
        <v-card-actions v-if="useReverseGeocoding">
          <v-btn
            depressed
            :small="singleAccordion"
            @click="$emit('reverse-geocoding')"
          >
            <v-icon left>mdi-target</v-icon>
            選択点を住所に設定
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="useSaveButton || useCancelButton">
          <v-btn @click="$emit('save')" color="primary" v-if="useSaveButton">
            <v-icon left>mdi-content-save-edit</v-icon>
            保存
          </v-btn>
          <v-btn @click="$emit('cancel')" v-if="useCancelButton">
            <v-icon left>mdi-close</v-icon>
            終了
          </v-btn>
        </v-card-actions>
      </div>
    </v-scale-transition>
  </v-card>
</template>

<script>
import controlAccordion from './controlAccordion';

export default {
  props: {
    drawController: { type: Object, required: true },
    useColor: { type: Boolean, default: () => false },
    useReverseGeocoding: { type: Boolean, default: () => false },
    useSaveButton: { type: Boolean, default: () => false },
    useCancelButton: { type: Boolean, default: () => false },
    // アコーディオンにするか
    useAccordion: { type: Boolean, default: true },
  },
  data: () => ({
    // パレット最大化最小化
    showToolBox: true,
    // 色選択表示非表示
    showColor: false,
    // 描画色
    color: '#000000',
    // 有効無効
    enable: false,
    // 描画ツールモード
    mode: 0,
    // 地点検索文字列
    searchText: null,
    isLoading: false,
    items: [],
    // アコーディオンで開いているもの
    openedAccordionArray: ['tool', 'edit', 'search'],
  }),
  watch: {
    color(val) {
      // 描画色設定
      this.drawController.setColor(val);
    },
    mode(val) {
      this.drawController.setDrawingMode(this.drawingModes[val].mode, {});
    },
  },
  computed: {
    drawingModes() {
      return [
        { mode: '', icon: 'mdi-cursor-move' },
        ...(this.drawController.drawingModes || []).map((mode) => {
          const info = { mode };
          switch (mode) {
            // マーカー
            case 'marker':
              info.icon = 'mdi-map-marker';
              break;
            // 多角線
            case 'polyline':
              info.icon = 'mdi-vector-polyline';
              break;
            // 経路探索
            case 'road':
              info.icon = 'mdi-road-variant';
              break;
            // 四角
            case 'rectangle':
              info.icon = 'mdi-rectangle-outline';
              break;
            // 円
            case 'circle':
              info.icon = 'mdi-circle-outline';
              break;
            // 同心円
            case 'multicircle':
              info.icon = 'mdi-circle-double';
              break;
            // 多角形
            case 'polygon':
              info.icon = 'mdi-vector-polygon';
              break;
          }
          return info;
        }),
      ];
    },
    // アコーディオンで開けるのは1つだけにするか
    singleAccordion() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    // 開いているアコーディオン
    openedAccordion: {
      get() {
        if (this.singleAccordion) {
          // 1つだけの時は配列の0番目だけ取り出し
          const firstElem = _.get(this, 'openedAccordionArray[0]');
          return firstElem ? [firstElem] : [];
        } else {
          // 通常はそのまま配列を返す
          return this.openedAccordionArray || [];
        }
      },
      // 各accordionのnameに指定された項目が入る
      set(val) {
        // 値が入っている場合はそれを除外した配列を作成
        if (this.openedAccordionArray?.includes(val)) {
          this.openedAccordionArray = this.openedAccordionArray.filter(
            (v) => v != val,
          );
        } else {
          if (this.singleAccordion) {
            // 1つだけの時はそれだけを要素にもつ配列をセット
            this.openedAccordionArray = [val];
          } else {
            // それ以外の時は配列に追加
            this.openedAccordionArray = [val, ...this.openedAccordionArray];
          }
        }
      },
    },
  },
  async mounted() {
    const mode = this.drawController.getDrawingMode();
    if (mode) {
      this.mode = _.findIndex(this.drawingModes, { mode });
    }
    // 地点情報検索
    const searchDOM = this.$refs['map-search'].$el;
    const inputDOM = searchDOM.querySelector('input');
    const autocomplete = new google.maps.places.Autocomplete(inputDOM, {
      bounds: google.maps.Map.defaultBounds,
    });
    // 選択時動作
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      this.move(place);
    });

    // フルスクリーンの状態で地点検索するとautocompleteの一覧が隠れるため回避
    // https://stackoverflow.com/a/60076220
    document.onfullscreenchange = function (event) {
      const target = event.target;
      const pacContainerElements = document.getElementsByClassName(
        'pac-container',
      );
      if (pacContainerElements.length > 0) {
        const pacContainer = document.getElementsByClassName('pac-container')[0];
        if (pacContainer.parentElement === target) {
          document.getElementsByTagName('body')[0].appendChild(pacContainer);
          pacContainer.className += pacContainer.className.replace(
            'fullscreen-pac-container',
            '',
          );
        } else {
          target.appendChild(pacContainer);
          pacContainer.className += ' fullscreen-pac-container';
        }
      }
    };
  },
  methods: {
    move({ geometry }) {
      this.drawController.getMap().setCenter(geometry.location);
    },
    /**
     * 選択中のオブジェクトを削除する
     */
    doDelete() {
      const { selectedFeature } = this.drawController;
      if (selectedFeature) {
        this.drawController.recordCommand(
          'delete',
          selectedFeature,
          (d) => this.drawController.deleteFeature(d),
          (d) => this.drawController.addFeature(d),
        );
      }
    },
  },
  components: {
    controlAccordion,
  },
};
</script>
<style scoped>
#colorSample {
  width: 2em;
  height: 2em;
  border: 1px gray solid;
}
</style>
