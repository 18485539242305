<template>
  <v-color-picker
    v-if="!context.attributes.disabled"
    v-model="color"
    hide-canvas
    hide-inputs
    hide-mode-switch
    show-swatches
    v-bind="context.attributes"
    v-on="context.attributes.handler"
  ></v-color-picker>
  <disabled v-else :label="context.label" :value="context.model" />
</template>

<script>
import disabled from './disabled';
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    disabled,
  },
  computed: {
    color: {
      get() {
        return this.context.model;
      },
      set(v) {
        this.$set(this.context, 'model', v);
      },
    },
  },
};
</script>
