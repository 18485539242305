import Vue from 'vue';
import _String from '@/components/inputs/string';
import _Picklist from '@/components/inputs/picklist';
import _MultiPicklist from '@/components/inputs/multiPicklist';
import _Datetime from '@/components/inputs/date-time';
import _Reference from '@/components/inputs/reference';
import _ManyToMany from '@/components/inputs/manytomany';
import _Textarea from '@/components/inputs/textarea';
import _Boolean from '@/components/inputs/boolean';
import _Location from '@/components/inputs/location';
import _LocationDialog from '@/components/inputs/location-dialog';
import _Features from '@/components/inputs/features';
import _Number from '@/components/inputs/number';
import _Integer from '@/components/inputs/integer';
import _Url from '@/components/inputs/url';
import _Range from '@/components/inputs/range';
import _Color from '@/components/inputs/color';
import _Date from '@/components/inputs/date';
import _DeepField from '@/components/inputs/deepfield';

Vue.component('String', _String);
Vue.component('Picklist', _Picklist);
Vue.component('MultiPicklist', _MultiPicklist);
Vue.component('Datetime', _Datetime);
Vue.component('Reference', _Reference);
Vue.component('ManyToMany', _ManyToMany);
Vue.component('TextareaInput', _Textarea);
Vue.component('Boolean', _Boolean);
Vue.component('Location', _Location);
Vue.component('LocationDialog', _LocationDialog);
Vue.component('Features', _Features);
Vue.component('Number', _Number);
Vue.component('Integer', _Integer);
Vue.component('Url', _Url);
Vue.component('Range', _Range);
Vue.component('Color', _Color);
Vue.component('Date', _Date);
Vue.component('DeepField', _DeepField);

export default {
  string: {
    classification: 'text',
    component: 'String',
  },
  email: {
    classification: 'text',
    component: 'String',
  },
  phone: {
    classification: 'text',
    component: 'String',
  },
  picklist: {
    classification: 'text',
    component: 'Picklist',
  },
  multipicklist: {
    classification: 'text',
    component: 'MultiPicklist',
  },
  'date-time': {
    classification: 'text',
    component: 'Datetime',
  },
  reference: {
    classification: 'text',
    component: 'Reference',
  },
  manytomany: {
    classification: 'text',
    component: 'ManyToMany',
  },
  textarea: {
    classification: 'text',
    component: 'TextareaInput',
  },
  number: {
    classification: 'text',
    component: 'Number',
  },
  integer: {
    classification: 'text',
    component: 'Integer',
  },
  boolean: {
    classification: 'box',
    component: 'Boolean',
  },
  location: {
    classification: 'text',
    component: 'Location',
  },
  'location-dialog': {
    classification: 'text',
    component: 'LocationDialog',
  },
  features: {
    component: 'Features',
  },
  url: {
    classification: 'text',
    component: 'Url',
  },
  range: {
    classification: 'text',
    component: 'Range',
  },
  color: {
    classification: 'text',
    component: 'Color',
  },
  date: {
    classification: 'text',
    component: 'Date',
  },
  deepfield: {
    classification: 'text',
    component: 'DeepField',
  },
};
