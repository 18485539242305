<template>
  <v-app dark>
    <!-- ローディング -->
    <Loading overlay>
      <v-progress-circular
        indeterminate
        color="primary"
        :size="60"
        :width="6"
      ></v-progress-circular>
    </Loading>
    <!--スナックバー-->
    <Snackbar />
    <!--コンテンツ-->
    <v-main style="background-color: #f5f5f5;">
      <slot name="insideContent">
        <v-container fluid fill-height>
          <v-row justify="center" align="center">
            <v-col
              cols="12"
              style="max-width: 500px;"
              :class="{ 'px-0': isMobile }"
            >
              <slot />
            </v-col>
          </v-row>
        </v-container>
      </slot>
    </v-main>
    <!-- ホットリロード -->
    <HotReloadMonitor />
  </v-app>
</template>

<script>
import Loading from '@/components/core/Loading';
import HotReloadMonitor from '@/components/core/HotReloadMonitor';
import Snackbar from '@/components/app/Snackbar';

export default {
  components: {
    Loading,
    HotReloadMonitor,
    Snackbar,
  },
  data: () => ({}),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
