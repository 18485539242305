<template>
  <v-app-bar id="app-bar" height="47px" max-height="47px" class="d-print-none">
    <!-- タイトル -->
    <router-link
      :to="$transition.getFallbackLink()"
      class="d-flex align-center v-list-item--link cds-link"
      style="white-space: nowrap;"
      @click.native="$store.dispatch('loading/setTransition', true)"
    >
      <v-avatar size="35">
        <img alt="Logo" :src="icon" />
      </v-avatar>
      <div class="text-h6 d-none d-sm-inline ml-2">
        {{ title }}
      </div>
    </router-link>
    <v-chip v-if="!isProduction" dark small color="deep-orange" class="mx-3">
      検証環境
    </v-chip>
    <v-spacer />
    <!-- メニュー -->
    <MegaMenu
      v-for="section in filteredSections"
      :key="section.Id"
      :section="section"
    />
    <!-- リンク集 -->
    <LinkCollection></LinkCollection>
    <!-- GISリンク -->
    <v-tooltip bottom :disabled="$vuetify.breakpoint.smAndDown">
      <template #activator="{ on }">
        <v-btn
          small
          text
          href="https://hamabosai.maps.arcgis.com/home/index.html"
          target="_blank"
          v-on="on"
        >
          GIS
          <v-icon small>
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </template>
      <span>浜松市防災情報GIS（防災マップ）を表示</span>
    </v-tooltip>
    <!-- ユーザ情報 -->
    <User />
    <!-- ローディング -->
    <Loading v-if="!showDisaster" progress />
  </v-app-bar>
</template>

<script>
import User from '@/components/core/parts/User';
import LinkCollection from '@/components/core/parts/LinkCollection';
import MegaMenu from '@/components/core/MegaMenu';
import Loading from '@/components/core/Loading';
import constant from '@/assets/js/constant';

export default {
  name: 'DashboardCoreAppBar',

  components: {
    User,
    LinkCollection,
    MegaMenu,
    Loading,
  },

  data: () => ({
    title: constant.systemName,
    icon: constant.systemIcon,
  }),

  computed: {
    // 災害非関連セクションをフィルタ
    filteredSections() {
      return (
        this.$store.state.menu.menusection
          ?.filter((section) => !section.IsDisaster__c)
          .map((section) => section) || []
      );
    },
    showDisaster() {
      return (
        this.$store.state.disaster.disaster.Id &&
        this.$store.state.user.organization.Id
      );
    },
    // 本番環境か
    isProduction() {
      return process.env.IS_PRODUCTION === 'true';
    },
  },

  methods: {},
};
</script>
