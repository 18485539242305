<template>
  <myTextField
    v-if="!context.attributes.disabled"
    v-model.number="val"
    outlined
    clearable
    dense
    hide-details="auto"
    :label="context.label"
    type="number"
    min="0"
    v-bind="context.attributes"
    @blur="context.blurHandler"
    @keypress="onKeypress"
    v-on="context.attributes.handler"
  />
  <disabled
    v-else
    :label="context.label"
    :value="context.model"
    :display-format="displayFormat"
  />
</template>

<script>
import disabled from './disabled';

import { VTextField } from 'vuetify/lib';

// 上書きする
// https://vuetifyjs.com/ja/getting-started/frequently-asked-questions/#questions
// https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VTextField/VTextField.ts
const myTextField = {
  extends: VTextField,

  methods: {
    // 常時clearボタンを表示させたいので上書き
    genClearIcon() {
      if (!this.clearable) return null;

      return this.genSlot('append', 'inner', [
        this.genIcon('clear', this.clearableCallback),
      ]);
    },
  },
};

export default {
  components: {
    disabled,
    myTextField,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    val: {
      get() {
        return this.context.model;
      },
      set(newValue) {
        this.$set(
          this.context,
          'model',
          !newValue && newValue !== 0 ? null : newValue,
        );
      },
    },
  },
  methods: {
    onKeypress(event) {
      // 正の整数だけ許可する
      if (!/^\d+$/.test(event.key)) {
        event.preventDefault();
      }
    },
    displayFormat(value) {
      if (value === 0) return '0';
      // 数値の場合はそのまま
      if (typeof value === 'number') {
        return value.toLocaleString();
      }
      // 文字列の数値の場合
      if (typeof value === 'string' && value !== '' && !isNaN(value)) {
        const num = Number(value);
        return num.toLocaleString();
      }
      // 数値ではなかったらそのまま返す
      return value;
    },
  },
};
</script>
