var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"tile":"","close-on-content-click":false,"offset-y":"","nudge-bottom":"5","min-width":"100%","content-class":"elevation-0","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c('v-btn',_vm._g({staticClass:"pl-sm-3 pr-sm-2 pl-2 pr-1",attrs:{"text":"","tile":"","small":_vm.$vuetify.breakpoint.xs}},{ ...onMenu }),[_vm._v(" "+_vm._s(_vm.section.Name)+" "),(_vm.megamenu)?_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-up ")]):_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}]),model:{value:(_vm.megamenu),callback:function ($$v) {_vm.megamenu=$$v},expression:"megamenu"}},[_c('v-card',{attrs:{"tile":"","outlined":"","elevation":"0"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-col',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xs },style:({ 'max-width': _vm.sectionMenu.maxWidth })},[_c('v-row',{class:[_vm.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-2'],attrs:{"no-gutters":""}},_vm._l((_vm.sectionMenu.chunkItems),function(chunkItem){return _c('v-col',{key:chunkItem.id,attrs:{"cols":12 / _vm.sectionMenu.chunkCols}},_vm._l((chunkItem),function(item){return _c('div',{key:item.id},[(_vm.showSwitch || item.enable)?_c('v-card',{attrs:{"disabled":!item.enable,"elevation":"0","tile":"","to":_vm.$transition.getToLink(item.path, null, {
                    notApplyRetUrl: true,
                  })},nativeOn:{"click":function($event){return _vm.handleOnClick()}}},[_c('v-card-title',{class:[
                    { 'pl-2': _vm.$vuetify.breakpoint.xs },
                    'pa-1',
                    'subtitle-1',
                  ]},[_c('v-icon',{attrs:{"left":"","color":item.color,"disabled":!item.enable}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.title))]),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [(item.gis)?_c('v-icon',_vm._g({attrs:{"right":"","small":""}},{ ...onTooltip }),[_vm._v(" mdi-earth ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("GIS表示あり")])]),_c('v-spacer'),_c('v-icon',{staticClass:"mr-2 d-flex d-sm-none",attrs:{"right":""}},[_vm._v(" mdi-chevron-right ")])],1)],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.helpSwitch && (_vm.showSwitch || item.enable))?_c('div',{staticClass:"caption text--secondary px-2 pb-2",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.hint)+" ")]):_vm._e()])],1)}),0)}),1),_c('div',{staticClass:"d-flex"},[_c('v-switch',{staticClass:"pl-3",attrs:{"label":"すべての機能を表示","dense":""},model:{value:(_vm.showSwitch),callback:function ($$v) {_vm.showSwitch=$$v},expression:"showSwitch"}}),_c('v-switch',{staticClass:"pl-3",attrs:{"label":"説明表示","dense":""},model:{value:(_vm.helpSwitch),callback:function ($$v) {_vm.helpSwitch=$$v},expression:"helpSwitch"}})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }