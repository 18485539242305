var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"time-number-selector",class:{
    'time-number-selector': true,
    'overflow-y-auto': true,
    disabled: !_vm.canEdit,
  },style:({
    height: _vm.height,
    width: _vm.width,
  })},[(_vm.label)?_c('div',{staticClass:"select-label text-center orange darken-1 white--text py-1"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_vm._l((_vm.items),function(item){return _c('div',{key:item,staticClass:"select-number text-center py-1",class:{ selected: item === _vm.editValue, disabled: !_vm.canEdit },on:{"click":function($event){return _vm.handleClickItem(item)}}},[_vm._v(" "+_vm._s(item)+" ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }