<template>
  <div
    class="bar-card-tab elevation-1"
    :class="{ active, pointer: !!on.click && !active }"
    v-on="on"
  >
    <slot>
      <span>
        <v-icon v-if="icon" class="mx-0">
          {{ icon }}
        </v-icon>
        {{ title }}
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: true,
    },
    on: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-card-tab {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-top: thin solid #e0e0e0;
  border-bottom: thin solid #e0e0e0;
  border-left: thin solid #e0e0e0;
  border-right: thin solid #e0e0e0;
  padding: 0 12px;
  font-size: 14px;
  background: #f5f5f5;

  &.active {
    background: white;
    border-bottom: thin solid white;
  }

  &.pointer {
    cursor: pointer;
  }

  &:not(.active) {
    transition: 0.2s;

    &:hover {
      background: #e0e0e0;
    }
  }
}
</style>
