<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>発生日時</v-list-item-title>
      </v-list-item-content>
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ $disaster.HappenDate__c | datetime }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>対応状況</v-list-item-title>
      </v-list-item-content>
      <v-list-item-content>
        <v-list-item-subtitle>
          <v-chip
            dark
            small
            :color="$disaster.DisasterStatus__c === '対応中' ? 'red' : ''"
            >{{ $disaster.DisasterStatus__c }}</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>GISへの表示</v-list-item-title>
      </v-list-item-content>
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ $disaster.UseGIS__c }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>FUJISAN連携</v-list-item-title>
      </v-list-item-content>
      <v-list-item-content>
        <v-list-item-subtitle>
          <v-icon :color="isFujisanConnected ? 'red' : ''" class="mr-2">{{
            isFujisanConnected
              ? 'mdi-power-plug-outline'
              : 'mdi-power-plug-off-outline'
          }}</v-icon>
          <span v-text="isFujisanConnected ? '接続済み' : '未接続'" />
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  computed: {
    $disaster() {
      return this.$store.state.disaster.disaster;
    },
    disasterColor: function () {
      switch (this.$store.state.disaster.disaster.DisasterType__c) {
        case '実災害':
          return 'red';
        case '訓練':
          return 'green';
        default:
          return '';
      }
    },
    isFujisanConnected() {
      return this.$store.getters['disaster/isFujisanConnected'];
    },
  },
};
</script>

<style scoped></style>
