<template>
  <v-menu
    v-model="megamenu"
    tile
    :close-on-content-click="false"
    offset-y
    nudge-bottom="5"
    min-width="100%"
    content-class="elevation-0"
    allow-overflow
  >
    <template #activator="{ on: onMenu }">
      <v-btn
        text
        tile
        :small="$vuetify.breakpoint.xs"
        class="pl-sm-3 pr-sm-2 pl-2 pr-1"
        v-on="{ ...onMenu }"
      >
        {{ section.Name }}
        <v-icon v-if="megamenu" right>
          mdi-chevron-up
        </v-icon>
        <v-icon v-else right>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>

    <v-card tile outlined elevation="0">
      <div class="d-flex justify-center">
        <!-- アイコン羅列部 -->
        <v-col
          :class="{ 'pa-0': $vuetify.breakpoint.xs }"
          :style="{ 'max-width': sectionMenu.maxWidth }"
        >
          <v-row no-gutters :class="[$vuetify.breakpoint.xs ? 'pa-0' : 'pa-2']">
            <v-col
              v-for="chunkItem in sectionMenu.chunkItems"
              :key="chunkItem.id"
              :cols="12 / sectionMenu.chunkCols"
            >
              <div v-for="item in chunkItem" :key="item.id">
                <v-card
                  v-if="showSwitch || item.enable"
                  :disabled="!item.enable"
                  elevation="0"
                  tile
                  :to="
                    $transition.getToLink(item.path, null, {
                      notApplyRetUrl: true,
                    })
                  "
                  @click.native="handleOnClick()"
                >
                  <v-card-title
                    :class="[
                      { 'pl-2': $vuetify.breakpoint.xs },
                      'pa-1',
                      'subtitle-1',
                    ]"
                  >
                    <v-icon left :color="item.color" :disabled="!item.enable">
                      {{ item.icon }}
                    </v-icon>
                    <span> {{ item.title }}</span>
                    <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown">
                      <template #activator="{ on: onTooltip }">
                        <v-icon
                          v-if="item.gis"
                          right
                          small
                          v-on="{ ...onTooltip }"
                        >
                          mdi-earth
                        </v-icon>
                      </template>
                      <span>GIS表示あり</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>
                    <v-icon right class="mr-2 d-flex d-sm-none">
                      mdi-chevron-right
                    </v-icon>
                  </v-card-title>
                </v-card>
                <transition name="fade">
                  <div
                    v-if="helpSwitch && (showSwitch || item.enable)"
                    class="caption text--secondary px-2 pb-2"
                    style="white-space: pre-line;"
                  >
                    {{ item.hint }}
                  </div>
                </transition>
              </div>
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-switch
              v-model="showSwitch"
              label="すべての機能を表示"
              class="pl-3"
              dense
            ></v-switch>
            <v-switch
              v-model="helpSwitch"
              label="説明表示"
              class="pl-3"
              dense
            ></v-switch>
          </div>
        </v-col>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  computed: {
    megamenu: {
      get() {
        if (!this.sectionId) return false;
        return this.$store.state.menu.activeMenues.includes(this.sectionId);
      },
      set(newValue) {
        if (!this.sectionId) return;
        this.$store.dispatch('menu/setActiveMenues', {
          name: this.sectionId,
          value: newValue,
        });
      },
    },
    sectionId() {
      return this.section?.Id || '';
    },
    sectionMenu() {
      // ユーザの権限
      const { Permission__c = '', isAdmin } = this.$store.state.user.user;
      const permissionList = Permission__c.split(';');
      // メニューデータをstoreから読み込み
      const menuitem = this.$store.state.menu.menuitem;
      if (!menuitem) return {};
      let items = menuitem
        .filter((item) => item.CDS_C_MenuSection__c === this.section.Id)
        .map((item) => {
          // 利用権限制御
          let enable = false;
          // 所属権限グループが利用可能権限グループに含まれるか
          if (item.Permission__c) {
            enable = item.Permission__c.split(';').some((d) =>
              permissionList.includes(d),
            );
          } else {
            enable = true;
          }
          // 管理者の場合
          enable = enable || isAdmin;
          // 災害選択済みのみ表示
          const disaster = this.$store.state.disaster.disaster;
          if (!disaster.Id) {
            enable = enable && item.UnnecessaryDisaster__c;
          }
          let retObj = {
            title: item.Name,
            icon: item.Icon__c,
            path: item.Path__c,
            hint: item.Hint__c,
            sort: item.Sort__c,
            color: item.Color__c,
            gis:
              (item.LinkGIS__c && disaster.UseGIS__c == '表示する') ||
              (item.LinkGIS__c && item.UnnecessaryDisaster__c),
            enable,
          };
          if (this.showSwitch) {
            return retObj;
          } else if (enable) {
            return retObj;
          }
          return;
        })
        .filter((item) => typeof item !== 'undefined');
      items = _.sortBy(items, 'sort');
      let chunkSize = {
        xs: 999, // xsは強制的に1列表示
        sm: Math.ceil(items.length / 2),
        md: this.calculateChunkSize(items.length, 3),
        lg: this.calculateChunkSize(items.length, 4),
        xl: this.calculateChunkSize(items.length, 4),
      };
      let chunkItems = _.chunk(items, chunkSize[this.$vuetify.breakpoint.name]);
      let chunkCols = chunkItems.length;
      // 列数が異なる場合のデザイン上の間延びを避ける
      let maxWidth =
        { 1: '600px', 2: '800px', 3: '1000px', 4: '1300px' }[chunkCols] ||
        '1500px';
      return { chunkItems, chunkCols, maxWidth };
    },
    showSwitch: {
      get() {
        return this.$store.state.menu.showAll;
      },
      set(newValue) {
        // this.sectionMenu;
        this.$store.dispatch('menu/setShowAll', !!newValue);
      },
    },
    helpSwitch: {
      get() {
        return this.$store.state.menu.showHelp;
      },
      set(newValue) {
        this.$store.dispatch('menu/setShowHelp', !!newValue);
      },
    },
  },

  methods: {
    async handleOnClick() {
      this.megamenu = false;
      this.$store.dispatch('loading/setTransition', true);
    },
    calculateChunkSize(itemLength, defaultSize) {
      // item数が少ない行数が少なくなってしまうので最低サイズ数でまとめるよう調整する
      const minChunkSize = 4;
      return Math.ceil(itemLength / defaultSize) <= minChunkSize
        ? minChunkSize
        : Math.ceil(itemLength / defaultSize);
    },
  },
};
</script>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: 0.5s;
}
.fade-enter-to {
  opacity: 1;
}
</style>
