<template>
  <v-card flat max-width="500" class="mx-auto">
    <v-card-title>
      <v-icon large left :color="color">
        {{ icon }}
      </v-icon>
      <span class="title font-weight-light">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      <v-list-item
        v-for="{ fieldPath } in fieldSet.fields"
        :key="fieldPath"
        dense
        class="dataWindowItem"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ getLabel(fieldPath) }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="getType(fieldPath) === 'date-time'">
            {{ getValue(fieldPath) | datetimesec }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="getType(fieldPath) === 'boolean'">
            <v-icon>
              {{
                getValue(fieldPath)
                  ? 'mdi-checkbox-marked'
                  : 'mdi-checkbox-blank-outline'
              }}
            </v-icon>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            {{ getValue(fieldPath) || '-' }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="url" color="primaly" @click="open">
        詳細情報を開く
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    object: { type: Object, default: () => ({}) },
    title: { type: String, default: null },
    icon: { type: String, default: null },
    color: { type: String, default: null },
    url: { type: String, default: null },
    fieldSet: { type: Object, default: () => ({}) },
    objectInfo: { type: Object, default: () => ({}) },
  },
  methods: {
    open() {
      window.open(this.url);
    },
    getLabel(fieldPath) {
      return _.get(this.objectInfo.properties, `${fieldPath}`)?.label;
    },
    getValue(fieldPath) {
      return _.get(this.object, `${fieldPath}`);
    },
    getType(fieldPath) {
      return _.get(this.objectInfo.properties, `${fieldPath}`)?.type;
    },
  },
};
</script>
<style scoped>
.dataWindowItem >>> .v-list-item__content {
  padding: 0 !important;
}
</style>
