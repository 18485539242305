<template>
  <FormulateForm v-model="fieldValue">
    <v-row class="flex-wrap flex-sm-nowrap my-0">
      <v-col cols="12" sm="5" class="flex-grow-1 flex-shrink-0 py-0">
        <div class="d-flex">
          <span class="mr-2 red--text" v-if="required">*</span>
          <span class="mr-2 red--text" v-else />
          <div style="flex: 1;">
            <FormulateInput
              name="gte"
              :label="context.label"
              :hint="context.attributes.inlineHelpText"
              :type="context.attributes.originType"
              :disabled="context.attributes.disabled"
              :id="_.uniqueId('range-from-')"
              v-bind="context.attributes"
            >
              <template #label>
                <span />
              </template>
            </FormulateInput>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="2" class="flex-grow-0 flex-shrink-1 pa-0">
        <div class="text-center my-2">
          <span>～</span>
        </div>
      </v-col>
      <v-col cols="12" sm="5" class="flex-grow-1 flex-shrink-0 py-0">
        <div class="d-flex">
          <span class="mr-2 red--text" v-if="required">*</span>
          <span class="mr-2 red--text" v-else />
          <div style="flex: 1;">
            <FormulateInput
              name="lte"
              :label="context.label"
              :hint="context.attributes.inlineHelpText"
              :type="context.attributes.originType"
              :disabled="context.attributes.disabled"
              :id="_.uniqueId('range-to-')"
              v-bind="context.attributes"
            >
              <template #label>
                <span />
              </template>
            </FormulateInput>
          </div>
        </div>
      </v-col>
    </v-row>
  </FormulateForm>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    required: false,
  }),
  computed: {
    fieldValue: {
      get() {
        return this.context.model || {};
      },
      set(value) {
        if (_.isObject(value)) {
          this.$set(
            this.context,
            'model',
            _.pickBy(
              value,
              (v) => _.isNumber(v) || _.isBoolean(v) || !_.isEmpty(v),
            ),
          );
        } else {
          this.$set(this.context, 'model', value);
        }
      },
    },
  },
};
</script>
