<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-2">
      <div>
        ユーザを選択
      </div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="ユーザを検索"
        single-line
        hide-details
        clearable
        outlined
        dense
        style="width: 230px;"
        class="flex-grow-0"
      ></v-text-field>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="user"
      item-key="Id"
      show-select
      class="cdsTable chat-user-selector-table"
      :loading="loading"
      :mobile-breakpoint="0"
      :search="search"
    >
      <template #item.Name="{ value: v, item }">
        <v-badge
          bordered
          dot
          offset-x="10"
          offset-y="10"
          inline
          :color="
            2 * 60 * 1000 > Date.now() - Date.parse(item.LastLoginDate)
              ? 'green'
              : 'grey'
          "
        >
        </v-badge>
        <span>
          {{ v }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { gql } from 'graphql-tag';
export default {
  name: 'Chat',

  components: {},

  props: {
    value: { type: Array, required: true },
    exceptUserIds: {
      type: Array,
      default: () => [],
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        text: '組織名',
        value: 'OrganizationName',
      },
      {
        text: '氏名',
        value: 'Name',
      },
      {
        text: 'ユーザー名',
        value: 'Username',
      },
      {
        text: '所属名',
        value: 'Department__c',
      },
      {
        text: '電話番号',
        value: 'Phone',
      },
    ],
    user: [],
    organizations: [],
    loading: false,
    search: null,
  }),

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  watch: {
    isActive(to, from) {
      // 閉じたときに選択状態をクリアする
      if (!to && from) {
        this.selected = [];
      }
    },
  },

  async mounted() {
    this.searchUser();
  },

  methods: {
    /**
     * ユーザの検索
     */
    async searchUser() {
      this.loading = true;
      const [users, orgs] = await Promise.all([
        this.getUsers(),
        this.getOrganizations(),
      ]);

      this.user = (users || [])
        .map((user) => ({
          ...user,
          // 組織名を結合
          OrganizationName: orgs.find(
            (v) => v.Id === user.CDS_M_Organization__c,
          )?.Name,
        }))
        .filter((user) => !this.exceptUserIds.includes(user.Id));

      this.loading = false;
    },

    async getUsers() {
      const result = await this.$apollo.query({
        query: /* GraphQL */ gql`
          query UserList($id: [String!], $orderBy: [UserOrderByInput]) {
            listUsers(
              where: { Id: { notIn: $id }, IsValid__c: { equals: true } }
              orderBy: $orderBy
            ) {
              Id
              Username
              Name
              LastLoginDate
              Phone
              Department__c
              CDS_M_Organization__c
            }
          }
        `,
        variables: {
          id: [this.$store.state.user.user.Id],
          orderBy: [
            {
              Sort__c: 'ASC',
            },
          ],
        },
      });
      return result?.data?.listUsers;
    },

    async getOrganizations() {
      if (this.organizations?.length > 0) return this.organizations;
      const result = await this.$con.invoke({
        controller: 'CDS_CTR_Common',
        method: 'getReferenceData',
        params: {
          objectName: 'CDS_M_Organization__c',
        },
      });
      this.organizations = result;
      return result;
    },
  },
};
</script>

<style lang="scss">
.chat-user-selector-table {
  .v-data-footer {
    padding: 0;
    justify-content: center;

    .v-data-footer__select {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
