<template>
  <div class="d-flex">
    <v-tooltip bottom>
      <template #activator="{on}">
        <v-btn icon v-on="on" @click="() => $emit('reload')">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </template>
      <span>リロード</span>
    </v-tooltip>
    <CreateRoom @created="onCreated" @updated-dialog="menu = false" />
  </div>
</template>

<script>
import CreateRoom from './CreateRoom.vue';
export default {
  name: 'Chat',

  components: {
    CreateRoom,
  },

  data: () => ({
    menu: false,
  }),

  methods: {
    onCreated(id) {
      this.$emit('created', id);
    },
  },
};
</script>

<style scoped></style>
