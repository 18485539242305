<template>
  <Layout
    v-model="editValue"
    v-bind="searchForm.attrs"
    @submit-raw="handleSubmit"
  />
</template>

<script>
import Layout from '@/components/detail/Layout.vue';

export default {
  name: 'SearchForm',

  components: {
    Layout,
  },

  props: {
    formName: {
      type: String,
      default: 'GlobalSearchForm',
    },
    column: {
      type: String,
      default: '2',
    },
  },

  data: () => ({
    infoTypes: [],
  }),

  computed: {
    editValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    searchForm() {
      return {
        attrs: {
          name: this.formName,
          editMode: true,
          objectInfo: {
            label: 'グローバル検索オブジェクト',
            properties: {
              words: {
                label: 'フリーワード検索',
                type: 'string',
                helpText:
                  '選択中の災害に登録された情報を検索できます。\n空白（半角または全角スペース）で区切るとAND検索、カンマ（,）で区切るとOR検索できます。',
                inputProps: {
                  'persistent-hint': true,
                },
              },
              infoType: {
                label: '情報種類',
                type: 'multipicklist',
                format: 'multipicklist',
                picklistValues: this.infoTypes,
                enum: this.infoTypes.map(({ value }) => value),
              },
            },
          },
          layout: {
            fullName: 'GlobalSearch.GlobalSearchLayout',
            label: '検索レイアウト',
            objecttype: 'GlobalSearch',
            name: 'GlobalSearchLayout',
            sections: [
              {
                label: null,
                detailHeading: true,
                editHeading: true,
                column: this.column,
                items: [
                  {
                    type: 'field',
                    fieldPath: 'words',
                    required: true,
                  },
                  {
                    type: 'field',
                    fieldPath: 'infoType',
                    required: true,
                  },
                ],
              },
            ],
          },
        },
      };
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      await this.$store.dispatch(
        'loading/register',
        Promise.all([this.getInfoType()]),
      );
    },

    async getInfoType() {
      // 取得済みなら再取得しない
      if (this.infoTypes?.length > 0) return;
      try {
        const result = await this.$con.invoke({
          controller: 'CDS_CTR_Search',
          method: 'getInfoType',
        });
        console.log('infotype result', result);
        if (result?.types) {
          this.infoTypes = result.types;
        }
      } catch (error) {
        console.warn(error);
      }
    },

    handleSubmit(form) {
      this.$emit('submit-raw', form);
    },
  },
};
</script>
