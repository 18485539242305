<template>
  <v-card
    class="chat-frame float-left d-flex flex-column"
    :class="{ 'in-mobile': isMobile, 'in-pc': !isMobile }"
  >
    <v-toolbar dense color="primary" dark class="flex-grow-0">
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-icon left> mdi-chat </v-icon>チャット
      </v-toolbar-title>
      <v-spacer />
      <template v-if="fullscreenSelectable">
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <v-btn
              icon
              v-on="{ ...onTooltip }"
              @click.stop="showFullscreen = !showFullscreen"
            >
              <v-icon>
                {{
                  showFullscreen ? 'mdi-window-minimize' : 'mdi-window-maximize'
                }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ showFullscreen ? '縮小' : '拡大' }}</span>
        </v-tooltip>
      </template>
      <v-btn icon @click="() => $emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="d-flex flex-grow-1">
      <div
        :style="sideBarStyle"
        class="flex-shrink-0 flex-grow-0 custom-drawer elevation-2 d-flex flex-column"
        :class="{ 'drawer-show': drawer, 'drawer-hide': !drawer }"
      >
        <div>
          <v-list>
            <v-list-item>
              <span class="caption">ルームの選択</span>
              <v-spacer />
              <ChatMenu
                @created="onRoomCreate"
                @reload="() => loadRoomCoreFunc()"
              />
            </v-list-item>
          </v-list>
        </div>
        <v-divider></v-divider>
        <div class="flex-grow-1" style="flex-basis: 0; overflow-y: scroll">
          <RoomList
            v-model="chatRoomId"
            :rooms="rooms"
            :loading="roomListLoading"
            :sub-loading="roomListSubLoding"
            @input="
              if ($event) {
                drawer = false;
              }
            "
          />
        </div>
      </div>
      <v-container
        v-if="chatRoomId"
        class="chat-right-part pa-0 pt-2"
        :class="{ 'in-mobile': isMobile, 'in-pc': !isMobile }"
      >
        <ChatWindow :room-id="chatRoomId" />
      </v-container>
    </div>
  </v-card>
</template>

<script>
import RoomList from './RoomList.vue';
import ChatMenu from './Menu.vue';
import ChatWindow from './ChatWindow.vue';
import { gql } from 'graphql-tag';
import { throttle } from 'lodash';

export default {
  name: 'Chat',

  components: { RoomList, ChatMenu, ChatWindow },

  model: {
    prop: 'fab',
    event: 'input',
  },

  props: {
    fab: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    propRoomId: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    isReady: false,
    rooms: [],
    roomListLoading: false,
    roomListSubLoding: false,
    internalDrawer: true,
    // ルーム読み込み関数(コア)
    loadRoomCoreFunc: null,
    // ルーム読み込み関数
    loadRoomFunc: null,
    // ルーム読み込み関数(ゆっくり)
    loadRoomSlowFunc: null,
    // subしているもの
    currentSubs: {},
    // keepaliveのインターバルのId
    keepaliveIntervalId: null,
  }),

  computed: {
    isMobile() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
    },

    chatRoomId: {
      get() {
        return this.propRoomId || '';
      },
      set(newValue) {
        this.$emit('update:prop-room-id', newValue);
      },
    },

    drawer: {
      get() {
        // // チャットルームのidがなければ強制表示
        if (!this.chatRoomId) return true;
        // // モバイルでなければ強制表示
        if (!this.isMobile) return true;
        return this.internalDrawer;
      },
      set(newValue) {
        this.internalDrawer = newValue;
      },
    },

    sideBarStyle() {
      return {
        width: '290px',
        position: this.isMobile ? 'absolute' : undefined,
        'z-index': '99',
        height: this.isMobile ? 'calc(100% - 48px)' : '100%',
        background: 'white',
        'border-right': 'thin solid #E0E0E0',
      };
    },

    // 所属するルームに入っているメンバー
    roomsAllMembers() {
      const membersSet = new Set([
        ...this.rooms.reduce((prev, next) => {
          return [...prev, ...next.ChatRoomOnUser.map((u) => u.User.Id)];
        }, []),
        this.$store.state.user.user.Id,
      ]);
      return [...membersSet];
    },

    // ルームのId
    roomIds() {
      return this.rooms.map((r) => r.Id);
    },

    // 拡大可能
    fullscreenSelectable() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    showFullscreen: {
      get() {
        return this.fullscreen;
      },
      set(newValue) {
        this.$emit('update:fullscreen', newValue);
      },
    },
  },

  watch: {
    fab(to, from) {
      // 閉じたときにチャットエリアも閉じる
      if (!to && from) {
        this.chatRoomId = '';
      }
    },
  },

  async mounted() {
    this.isReady = true;
    // 読み込み用関数を用意(コア)
    this.loadRoomCoreFunc = throttle(() => this.loadRoomInfo(), 0.5 * 1000, {});
    // 読み込み用関数を用意
    this.loadRoomFunc = throttle(() => this.loadRoomCoreFunc(), 0.7 * 1000, {});
    // 読み込み用関数を用意(ゆっくり)
    this.loadRoomSlowFunc = throttle(
      () => this.loadRoomCoreFunc(),
      10 * 1000,
      {},
    );

    setTimeout(async () => {
      if (this.isReady) {
        // サブスクライブを登録
        await this.subscribe();
        // オンライン状態の更新
        this.keepalive();
        this.keepaliveIntervalId = setInterval(() => {
          this.keepalive();
        }, 1.5 * 60 * 1000);

        this.roomListLoading = true;
        await this.loadRoomFunc().catch(console.error);
        this.roomListLoading = false;
      }
    }, 2 * 1000);
  },

  async beforeDestroy() {
    this.isReady = false;
    // subを解除
    for (const key of Object.keys(this.currentSubs)) {
      await this.unSubscribe(key);
    }
    // keepaliveのインターバルを解除
    if (this.keepaliveIntervalId) {
      clearInterval(this.keepaliveIntervalId);
    }
  },

  methods: {
    /**
     * サブスクライブを行う
     */
    doSubscribe(query, variables, next) {
      return this.$apollo
        .subscribe({
          query,
          variables,
        })
        .subscribe({
          next,
          error: console.error,
        });
    },
    /**
     * サブスクライブを解除する
     */
    async unSubscribe(key) {
      const value = this.currentSubs[key];
      if (value) {
        for (const v of value) {
          try {
            await v?.unsubscribe?.();
          } catch (error) {
            console.warn(error);
          }
        }
      }
      this.currentSubs[key] = undefined;
      await this.$nextTick();
    },

    /**
     * チャットルームをサブスクライブする
     */
    async subscribe() {
      await this.subRoomUser();
      await this.subUser();
      await this.subMessage();
      await this.subRooms();
    },
    // ルームメンバー追加時のsub
    async subRoomUser() {
      const key = 'room_user_sub';
      // すでに登録済みであればおわり
      if (this.currentSubs[key]?.length > 0) return;
      this.currentSubs[key] = [
        this.doSubscribe(
          /* GraphQL */ gql`
            subscription CreatedManyChatRoomOnUsers {
              onCreatedChatRoomOnUser {
                Id
                userId
                chatRoomId
              }
            }
          `,
          {},
          ({ data }) => {
            const { userId } = data.onCreatedChatRoomOnUser;
            // 自分のユーザが呼ばれたときだけロード
            if (userId === this.$store.state.user.user.Id) {
              this.loadRoomFunc();
            }
          },
        ),
      ];
    },
    // ユーザ情報変更時のsub
    async subUser() {
      const key = 'user_sub';
      await this.unSubscribe(key);
      // ユーザを絞って登録
      this.currentSubs[key] = this.roomsAllMembers.map((userId) => {
        const sub = this.doSubscribe(
          /* GraphQL */ gql`
            subscription UserSubscribe($userId: String) {
              onUpdatedUser(Id: $userId) {
                Id
              }
            }
          `,
          {
            userId,
          },
          () => this.loadRoomSlowFunc(),
        );
        return sub;
      });
    },
    // メッセージ投稿時のsub
    async subMessage() {
      const key = 'message_sub';
      // すでに登録済みであればおわり
      if (this.currentSubs[key]?.length > 0) return;
      this.currentSubs[key] = [
        this.doSubscribe(
          /* GraphQL */ gql`
            subscription ChatMessageSubscribe {
              onCreatedChatMessage {
                Room {
                  Id
                }
              }
            }
          `,
          {},
          ({ data: { onCreatedChatMessage } }) => {
            // 所属しているルームのメッセージのみに絞り込み
            const roomId = onCreatedChatMessage.Room?.[0]?.Id;
            if (this.roomIds.includes(roomId)) {
              this.loadRoomFunc();
            }
          },
        ),
      ];
    },
    // ルーム変更時のsub
    async subRooms() {
      const key = 'room_sub';
      await this.unSubscribe(key);
      // ユーザを絞って登録
      this.currentSubs[key] = this.roomIds.map((roomId) => {
        const sub = this.doSubscribe(
          /* GraphQL */ gql`
            subscription RoomSubscribe($roomId: String) {
              onUpdatedChatRoom(Id: $roomId) {
                Id
                Name
              }
            }
          `,
          {
            roomId,
          },
          () => this.loadRoomFunc(),
        );
        return sub;
      });
    },

    /**
     * チャットルーム更新時イベント
     */
    async loadRoomInfo(option = { fetchPolicy: 'no-cache' }) {
      // console.log('loadRoom', new Date().toJSON());
      this.roomListSubLoding = true;
      const query = /* GraphQL */ gql`
        query getList($userId: StringFilter, $disaster: StringFilter) {
          listChatRooms(
            where: {
              ChatRoomOnUser: { some: { userId: $userId } }
              CDS_T_Disaster__c: $disaster
            }
          ) {
            CreatedDate
            Id
            Name
            ChatRoomOnUser {
              User {
                LastLoginDate
                Name
                Id
                Avatar
              }
            }
          }
        }
      `;
      const _rooms = (
        await this.$apollo.query({
          query,
          variables: {
            userId: { equals: this.$store.state.user.user.Id },
            disaster: { equals: this.$store.state.disaster.disaster.Id },
          },
          ...option,
        })
      ).data.listChatRooms;
      await Promise.all(
        _rooms.map(async (room) => {
          // ルーム参加者の表示から自分を除く
          room.ChatRoomOnUser = room.ChatRoomOnUser.filter(
            (d) => d.User.Id !== this.$store.state.user.user.Id,
          );
          // ルームの最新メッセージを取得
          const { data } = await this.$apollo.query({
            query: /* GraphQL */ gql`
              query getMessage(
                $roomID: String
                $orderBy: [ChatMessageOrderByInput]
              ) {
                listChatMessages(
                  where: { Room: { some: { Id: { equals: $roomID } } } }
                  orderBy: $orderBy
                  take: 1
                ) {
                  body
                  ChatMessageStatus {
                    Id
                    userId
                  }
                }
              }
            `,
            variables: {
              roomID: room.Id,
              orderBy: [{ CreatedDate: 'DESC' }],
            },
            ...option,
          });
          room.Messages = data.listChatMessages;
          room.hasNewMessage = this.hasNewMessage(room.Messages);
        }),
      );

      this.rooms = _rooms;
      this.$emit(
        'has-new-message',
        _rooms.some((r) => r.hasNewMessage),
      );
      // 更新情報を受け取るユーザを更新したいので再度登録
      await this.subscribe();
      this.roomListSubLoding = false;
    },

    /**
     * 新規メッセージが存在するか
     */
    hasNewMessage(messages) {
      const statuses = messages?.[0]?.ChatMessageStatus;
      if (!Array.isArray(statuses)) return false;
      return !statuses.find((v) => v.userId === this.$store.state.user.user.Id);
    },
    /**
     * ルーム作成時イベント
     */
    onRoomCreate(id) {
      this.chatRoomId = id;
    },
    /**
     * 最終ログイン日時を更新する
     */
    keepalive() {
      this.$apollo.mutate({
        mutation: /* GraphQL */ gql`
          mutation updateUserStatus($id: String!, $lastLoginDate: AWSDateTime) {
            updateUser(
              data: { LastLoginDate: $lastLoginDate }
              where: { Id: $id }
            ) {
              Id
              Username
              ChatRoomOnUser {
                userId
                chatRoomId
              }
            }
          }
        `,
        variables: {
          id: this.$store.state.user.user.Id,
          lastLoginDate: new Date().toISOString(),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-frame {
  right: 0;
  position: absolute;
  overflow: hidden;

  &.in-pc {
    height: calc(100vh - 100px);
  }

  &.in-mobile {
    height: 100%;
  }

  > .row {
    height: 100%;
  }

  > .v-sheet {
    display: flex;
    position: relative;
    height: 100%;
  }

  .chat-right-part {
    height: 100%;

    &.in-pc {
      width: 660px;
    }
  }
}

.custom-drawer {
  transition: transform 0.2s 0s cubic-bezier(0.4, 0, 0.2, 1);

  &.drawer-show {
    transform: translateX(0);
  }

  &.drawer-hide {
    transform: translateX(-100%);
  }
}
</style>

<style>
.chat-frame .v-toolbar__content {
  width: 100%;
}
</style>
