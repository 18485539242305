<template>
  <div v-if="!context.attributes.disabled">
    <v-autocomplete
      v-model="editValue"
      :items="items"
      :label="context.label"
      :search-input.sync="search"
      :loading="loading"
      cache-items
      outlined
      clearable
      dense
      item-text="Name"
      item-value="Id"
      hide-details="auto"
      multiple
      v-bind="context.attributes"
      @blur="context.blurHandler"
    >
      <template #item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.Name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
  <div v-else>
    <div class="caption">
      {{ context.label }}
    </div>
    <div class="body-1 my-2 mx-n1">
      <v-chip
        v-for="val in displayItems"
        :key="val.Id"
        :ripple="false"
        small
        class="mx-1"
        @click="toDetail(val)"
      >
        {{ val.Name }}
      </v-chip>
    </div>
    <v-divider />
  </div>
</template>

<script>
export default {
  inject: {
    manyToMany: {
      default: () => ({}),
    },
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: false,
    search: null,
    // 選択可能なアイテム
    items: [],
    // idを中心に
    readonlyItems: [],
  }),

  computed: {
    editValue: {
      get() {
        return this.valueIds;
      },
      set(newValue) {
        this.$set(
          this.context,
          'model',
          this.items.filter(({ Id }) => newValue.includes(Id)),
        );
      },
    },
    valueIds() {
      const v = this.context.model;
      return (
        (v && Array.isArray(v) && v?.filter((v) => !!v).map(({ Id }) => Id)) ||
        []
      );
    },
    valueItems() {
      return this.readonlyItems.filter(({ Id }) => this.valueIds.includes(Id));
    },
    displayItems() {
      const allItems = this.valueItems;
      return (
        allItems
          // 0報は除外する
          .filter((v) => Number(v.Version__c) !== 0 && v.ParentId__c)
          // 確定報のうち最新のものだけ取得する
          .reduce((prev, next) => {
            // 次のアイテムのparentIdとversionを取り出す
            const {
              ParentId__c: nextParentId,
              Version__c: nextVersionString,
            } = next;
            const nextVersion = Number(nextVersionString);
            // 同じparentIdのものを見つける
            const sameParentItem = prev.find(
              (v) => v.ParentId__c === nextParentId,
            );
            // 同じparentIdのものがない場合、もしくはあっても次のアイテムの方がバージョンが上の場合はそれを採用
            if (
              !sameParentItem ||
              Number(sameParentItem.Version__c) < nextVersion
            ) {
              return [
                ...prev.filter((v) => v.ParentId__c !== nextParentId),
                next,
              ];
            } else {
              return prev;
            }
          }, [])
      );
    },
    injectConfig() {
      return this.manyToMany[this.context.attributes.fieldPath] || {};
    },
  },

  watch: {
    'context.attributes.disabled': {
      deep: true,
      handler(to, from) {
        this.handleChangeDisabled(to, from);
      },
    },
  },

  mounted() {
    this.querySelections();
  },

  methods: {
    async querySelections() {
      this.loading = true;
      const objectName = this.context.attributes.reference?.match(
        /#\/definitions\/(.*)/,
      )[1];
      if (objectName) {
        const res = await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getRelationData',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
            objectName,
            ids: this.valueIds,
          },
        });
        if (res.errors) {
          console.error(res.errors);
          throw new Error(res.errors.message);
        } else {
          const { latestRecord, idsRecord } = res;
          // 選択可能なレコードのリスト
          this.items = latestRecord;
          // 初期で設定されているIdに紐づくレコードのリストと合算
          const total = [...idsRecord, ...latestRecord];
          const idSet = new Set(total.map(({ Id }) => Id));
          this.readonlyItems = [...idSet].map((Id) =>
            total.find((v) => v.Id === Id),
          );
        }
      }
      this.loading = false;
    },

    handleChangeDisabled(to, from) {
      if (from && !to) {
        const v = this.context.model;
        if (!v || !Array.isArray(v)) return v;
        const selectableIds = this.items.map((v) => v.Id);
        const result = v.filter(({ Id }) => selectableIds.includes(Id));
        this.$set(this.context, 'model', result);
      }
    },

    async toDetail(item) {
      const detailPageName = this.injectConfig.detailPageName;
      if (!detailPageName) return;
      this.$transition.to(
        detailPageName,
        { id: item.Id },
        { notApplyRetUrl: true, openNewTab: true },
      );
    },
  },
};
</script>
