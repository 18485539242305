<template>
  <v-app dark>
    <!-- ローディング -->
    <Loading overlay />
    <!--スナックバー-->
    <Snackbar />
    <AppBar />
    <v-divider></v-divider>
    <template v-if="showDisaster">
      <DisasterBar />
      <v-divider></v-divider>
    </template>
    <!--コンテンツ-->
    <v-main id="scrolling-techniques">
      <!-- メニュー表示時のoverlay -->
      <v-overlay :value="overlay" absolute z-index="4"></v-overlay>
      <slot name="insideContent">
        <v-container fluid fill-height class="flex-column">
          <div class="flex-shrink-0 flex-grow-0 align-self-stretch">
            <slot name="pageHeader" />
          </div>
          <div
            class="flex-grow-1 flex-shrink-0"
            :style="{
              width: '100%',
            }"
          >
            <slot />
          </div>
        </v-container>
      </slot>
    </v-main>
    <slot name="outsideContent" />
    <!-- ホットリロード -->
    <HotReloadMonitor />
  </v-app>
</template>

<script>
import AppBar from '@/components/core/AppBar';
import DisasterBar from '@/components/core/DisasterBar';
import Loading from '@/components/core/Loading';
import HotReloadMonitor from '@/components/core/HotReloadMonitor';
import Snackbar from '@/components/app/Snackbar';

export default {
  components: {
    AppBar,
    DisasterBar,
    Loading,
    HotReloadMonitor,
    Snackbar,
  },
  computed: {
    // 災害と組織が確定していない場合表示しない
    showDisaster() {
      return (
        this.$store.state.disaster.disaster.Id &&
        this.$store.state.user.organization.Id
      );
    },
    overlay() {
      // 開いているメニューがひとつでもあればoverlayを表示する
      return this.$store.state.menu.activeMenues.length !== 0;
    },
  },
};
</script>
