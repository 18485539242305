<template>
  <v-text-field
    v-if="!context.attributes.disabled"
    v-model="val"
    outlined
    dense
    hide-details="auto"
    :label="context.label"
    type="url"
    v-bind="context.attributes"
    @blur="context.blurHandler"
    v-on="context.attributes.handler"
  />
  <div v-else>
    <div v-if="val">
      <div class="caption">
        {{ context.label }}
      </div>
      <div>
        <a :href="val" target="_blank">{{ val }}</a>
      </div>
    </div>
    <disabled v-else :label="context.label" :value="val" />
  </div>
</template>

<script>
import disabled from './disabled';
export default {
  components: {
    disabled,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    val: {
      get() {
        return this.context.model;
      },
      set(newValue) {
        this.$set(this.context, 'model', newValue);
      },
    },
  },
};
</script>
