<template>
  <div>
    <div
      v-if="title"
      :class="{
        'text-caption': true,
        'ml-2': true,
        pointer: useAccordion,
      }"
      @click.stop.prevent="onClickTitle"
    >
      <template v-if="useAccordion">
        <template v-if="show">
          <v-icon small style="width: 1rem;">fas fa-caret-down</v-icon>
        </template>
        <template v-else>
          <v-icon small style="width: 1rem;">fas fa-caret-right</v-icon>
        </template>
      </template>
      {{ title }}
    </div>
    <div v-show="show || !useAccordion">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    useAccordion: {
      type: Boolean,
      default: true,
    },
    openFields: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    show() {
      if (!this.useAccordion) return true;
      if (!this.name) return true;
      return (this.openFields || []).includes(this.name);
    },
  },
  methods: {
    // タイトル部をクリックした時
    onClickTitle() {
      if (this.useAccordion) {
        this.$emit('toggle', this.name);
      }
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
