<template>
  <div v-if="!context.attributes.disabled">
    <v-autocomplete
      v-model="context.model"
      :items="items"
      :label="context.label"
      :search-input.sync="search"
      :loading="loading"
      cache-items
      outlined
      clearable
      dense
      item-text="Name"
      item-value="Id"
      hide-details="auto"
      v-bind="context.attributes"
      @blur="context.blurHandler"
    >
      <template #item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.Name }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-chip
      v-if="context.attributes.multiple"
      class="my-3"
      small
      @click="selectAll"
    >
      <v-avatar left>
        <v-icon>mdi-checkbox-marked-circle</v-icon>
      </v-avatar>
      すべて選択
    </v-chip>
  </div>
  <div v-else-if="context.attributes.multiple">
    <div class="caption">
      {{ context.label }}
    </div>
    <div class="body-1">
      <v-chip v-for="val in valueLabelList" :key="val" small class="mr-3">
        {{ val }}
      </v-chip>
    </div>
    <v-divider />
  </div>
  <disabled v-else :label="context.label" :value="valueLabel" />
</template>

<script>
import disabled from './disabled';
export default {
  components: {
    disabled,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    search: null,
    items: [],
  }),
  computed: {
    valueLabel() {
      const { Name, Id } = _.find(this.items, { Id: this.context.model }) || {};
      return Name;
    },
    valueLabelList() {
      if (!this.context.model) return [];
      return this.context.model.map((Id) => {
        const { Name } = _.find(this.items, { Id }) || {};
        return Name;
      });
    },
  },
  watch: {
    // search(val) {
    //   val && val !== this.select && this.querySelections(val);
    // },
  },
  mounted() {
    this.querySelections();
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      const objectName = this.context.attributes.reference?.match(
        /#\/definitions\/(.*)/,
      )[1];
      if (objectName) {
        const res = await this.$con.invoke({
          controller: 'CDS_CTR_Common',
          method: 'getReferenceData',
          params: {
            objectName,
          },
        });
        if (res.errors) {
          console.error(res.errors);
          throw new Error(res.errors.message);
        } else {
          this.items = res;
        }
      }
      this.loading = false;
    },
    selectAll() {
      this.context.model = this.items.map((d) => d.Id);
    },
  },
};
</script>
