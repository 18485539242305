<template>
  <v-lazy v-if="!isIE" v-bind="lazyProps">
    <slot></slot>
  </v-lazy>
  <div v-else>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    lazyProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isIE() {
      return window.document.documentMode != undefined ? true : false;
    },
  },
};
</script>
