<template>
  <FormulateForm v-model="modelValue">
    <FormulateInput :id="_.uniqueId('deep-field-')" v-bind="inputProps">
      <template #label>
        <span />
      </template>
    </FormulateInput>
  </FormulateForm>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    fieldPathArrah() {
      return (
        this.context.attributes.fieldPath?.split('.').filter((v) => !!v) || []
      );
    },
    fieldPath() {
      return this.fieldPathArrah[1];
    },
    modelValue: {
      get() {
        return this.context.model || {};
      },
      set() {
        // ignored
      },
    },
    inputProps() {
      return {
        ...this.context.attributes.vueformulateIntput,
        // 書き換え
        name: this.fieldPath,
        // 編集はさせない
        disabled: true,
        // もとのタイプに戻す
        type: this.context.attributes.originType,
      };
    },
  },
};
</script>
