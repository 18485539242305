<template>
  <div>
    <div class="caption">
      {{ label }}
    </div>
    <div class="body-1" style="white-space: pre-line;">
      {{ toDisplay(value) || '-' }}
    </div>
    <v-divider />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {},
    // 表示する時のフォーマット指定
    displayFormat: {
      type: Function,
      default: null,
    },
  },
  methods: {
    toDisplay(value) {
      if (this.displayFormat) {
        return this.displayFormat(value);
      }
      return value;
    },
  },
};
</script>
