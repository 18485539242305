<template>
  <div class="custom-time-picker elevation-2">
    <div class="preview">
      <input
        :value="editValue"
        type="text"
        placeholder="HH:mm"
        readonly
        autocomplete="off"
        class="text-center"
      />
    </div>
    <div class="time-selector d-flex justify-center">
      <TimeNumberSelector
        v-model="hour"
        class="selector-hour"
        label="時間"
        :active="active"
        :min="0"
        :max="23"
        :step="1"
        :height="height"
        :width="'50%'"
        :can-edit="canSelectHour"
      />
      <TimeNumberSelector
        v-model="minute"
        class="selector-minute"
        label="分"
        :active="active"
        :min="0"
        :max="59"
        :step="1"
        :height="height"
        :width="'50%'"
        :can-edit="canSelectMinute"
      />
    </div>
  </div>
</template>

<script>
import TimeNumberSelector from './time-number-selector.vue';

export default {
  name: 'TimePicker',
  components: { TimeNumberSelector },
  props: {
    value: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: String,
      default: '00:00',
    },
    height: {
      type: undefined,
      default: undefined,
    },
    canSelectHour: {
      type: Boolean,
      default: true,
    },
    canSelectMinute: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  computed: {
    // 編集用の値
    editValue: {
      get() {
        return this.value
          ? this.value.split(':').slice(0, 2).join(':')
          : this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    splitValue() {
      return (this.editValue || this.defaultValue).split(':');
    },
    hour: {
      get() {
        return this.splitValue?.[0];
      },
      set(newValue) {
        this.editValue = `${newValue}:${this.splitValue[1]}`;
      },
    },
    minute: {
      get() {
        return this.splitValue?.[1];
      },
      set(newValue) {
        this.editValue = `${this.splitValue[0]}:${newValue}`;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-time-picker {
  .preview {
    input {
      border: 1px solid #78909c;
      height: 2.2em;
      padding: 0.1em;
      font-size: 1.3em;
    }
  }
}
</style>
