<template>
  <v-sheet v-if="loading" class="pa-3">
    <v-skeleton-loader
      v-for="i of 5"
      :key="i"
      class="mx-auto"
      max-width="300"
      type="list-item-avatar"
    />
  </v-sheet>
  <v-list v-else>
    <template v-for="(item, index) in rooms">
      <v-list-item
        :key="index"
        dense
        class="chat-list-item py-2 d-flex"
        :class="{ selected: value === item.Id }"
        @click="() => $emit('input', item.Id)"
      >
        <div class="flex-grow-1 ml-3 overflow-x-hidden" style="flex-basis: 0;">
          <div class="text-subtitle-2 font-weight-bold">
            <template v-if="item.Name">
              {{ item.Name }}
            </template>
            <template v-else>
              {{ item.ChatRoomOnUser.map((d) => d.User.Name).join(',') }}
            </template>
            ({{ item.ChatRoomOnUser.length + 1 }})
          </div>
          <div class="caption overflow-x-hidden" style="white-space: nowrap;">
            {{ getLatestMessage(item) }}
          </div>
        </div>
        <div>
          <v-badge v-if="item.hasNewMessage" color="error" dot inline>
          </v-badge>
        </div>
      </v-list-item>
    </template>
    <v-list-item v-if="rooms.length === 0">
      <v-row class="my-0">
        <v-col cols="12" class="no-room-header">
          <v-icon>mdi-magnify</v-icon> 現在ルームはありません。
        </v-col>
        <v-col cols="12" class="pa-5">
          <span class="text-caption">
            右上の<v-icon>mdi-chat-plus</v-icon>をクリックしてルームを作成してください。
          </span>
        </v-col>
      </v-row>
    </v-list-item>
  </v-list>
</template>

<script>
import { AES as cryptoAES, enc as cryptoEnc } from 'crypto-js';

export default {
  name: 'Chat',

  props: {
    rooms: { type: Array, required: true },
    loading: Boolean,
    subLoading: Boolean,
    value: { type: String, default: '' },
  },

  methods: {
    getLatestMessage(item) {
      const content = item?.Messages?.[0]?.body;
      return content && this.decodeMessage(content, item.Id);
    },
    /**
     * メッセージの復元
     */
    decodeMessage(msg, key) {
      try {
        const decrypted = cryptoAES.decrypt(msg, key);
        return decrypted.toString(cryptoEnc.Utf8);
      } catch (error) {
        // ignored
      }
      return msg;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-room-header {
  text-align: center;
}
.chat-list-item {
  min-height: 50px;

  &.selected {
    background: #e1f5fe;
  }
}
.v-progress-circular {
  position: absolute;
  right: 12px;
  bottom: 64px;
}
</style>
