<template>
  <v-select
    v-if="!context.attributes.disabled"
    v-model="context.model"
    outlined
    :clearable="!context.attributes.fieldRequired"
    dense
    item-text="label"
    item-value="value"
    hide-details="auto"
    :label="context.label"
    :items="items"
    v-bind="context.attributes"
    persistent-hint
    @blur="context.blurHandler"
    v-on="context.attributes.handler"
  />
  <disabled
    v-else
    :label="context.label"
    :value="_dispValue"
    :display-format="context.attributes.displayFormat"
  />
</template>

<script>
import disabled from './disabled';
export default {
  components: {
    disabled,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      //picklistControllerが項目定義に関数として定義されていればそちらを実行してその値を選択リストの候補値とする
      if (typeof this.context.attributes.picklistController === 'function') {
        return this.context.attributes.picklistController();
      }
      return this.context.attributes.items;
    },
    _dispValue: {
      get() {
        // labelの値を表示する
        const items = this?.items;
        const values = (this.context.model || '')?.split(';') || [];
        if (!items) return this.context.model;

        return values
          .map((v) => {
            const label = items.find((i) => i.value === v)?.label || v;
            return label;
          })
          .join(';');
      },
    },
  },
  watch: {
    context: {
      handler() {
        if (
          !this.context.attributes.disabled &&
          !this.context.model &&
          !this.context.attributes.notApplyDefaultValue
        ) {
          //初期値を設定する
          const defaultVal =
            _.find(this.context.attributes.items, {
              defaultValue: true,
            }) || {};
          this.$set(this.context, 'model', defaultVal.value);
        }
      },
      deep: true,
    },
  },
};
</script>
