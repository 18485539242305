<template>
  <div style="position: relative;">
    <MapComponent :context="context" :can-draw="false"></MapComponent>
  </div>
</template>

<script>
// import MapComponent from '@/googlemap/googlemap';
import MapComponent from '@/arcGIS/esrimap';

export default {
  components: {
    MapComponent,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
};
</script>
