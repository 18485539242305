<template>
  <v-btn @click="$emit('click')" style="z-index: 2;">
    <v-icon>mdi-crop-free </v-icon>
    GISを表示
  </v-btn>
</template>

<script>
export default {
  props: {},
  methods: {},
};
</script>
<style scoped></style>
